import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';
import { iCred, iUser } from '../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeUser } from '../redux/userSlice';
import { changeCred } from '../redux/credSlice';
import { converteObjLogin } from '../utils/Converter';

type Config = {
    token?: string;
    isLogged: boolean;
    loading: boolean;
    user?: iUser;
    credentials?: iCred;
    updateConfig: (newConfig: Partial<Config>) => void;
    saveLogin: (data: any) => void;
    logout: () => void;
};

const defaultConfig: Config = {
    isLogged: false,
    loading: true,
    updateConfig: () => { },
    saveLogin: () => { },
    logout: () => { }
};

const AuthContext = createContext<Config>(defaultConfig);

export const AuthConfig = () => {
    return useContext(AuthContext);
};

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);
    const nav = useNavigate();
    const dispatch = useDispatch();

    const ExecLogin = (data: string) => {
        try {
            let dados_str = aes.decrypt(data, process.env.REACT_APP_KEY_AES || '').toString(utf8)
            let dados_user = JSON.parse(dados_str)
            window.localStorage.setItem("token", dados_user.token)
            dispatch(changeUser(converteObjLogin(dados_user)))
            dispatch(changeCred(dados_user.credentials))
            updateConfig({
                isLogged: true,
                user: dados_user,
                credentials: dados_user.credentials,
                token: dados_user.token,
                loading: false
            });
            if (dados_user.nivel === 1) { nav("/dashboard-max") } else { nav("/map") }


        } catch (error) {
            console.log('[CheckLogged]', error)
        }
    }

    const saveLogin = (data: any) => {
        try {
            window.localStorage.setItem("dados_token", data)
            ExecLogin(data)
        } catch (error) {
            console.log('[saveLogin]', error)
        }
    }

    const logout = () => {
        window.localStorage.removeItem("dados_token")
        window.localStorage.removeItem("token")
        updateConfig({
            isLogged: false,
            user: undefined,
            credentials: undefined,
            token: undefined,
            loading: false
        });
        nav("/")
    }

    const updateConfig = (newConfig: Partial<Config>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };



    useEffect(() => {
        let dados_token = window.localStorage.getItem('dados_token') || null
        if (dados_token) {
            ExecLogin(dados_token)
        } else {
            console.log("dados_token invalid")
            logout()
        }
    }, []);

    return (
        <AuthContext.Provider value={{ ...config, updateConfig, saveLogin, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
