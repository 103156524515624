import { httpGateway } from "../../../gateways/Axios";


const version = ''

export async function pushPostion(payload: any, sendTo: string): Promise<any> {
    let url = sendTo === 'prod' ? process.env.REACT_APP_IOTHUB_PROD : process.env.REACT_APP_IOTHUB_DEV
    const { data } = await httpGateway<any, any>(
        `${url}/pushPosition`,
        "POST",
        payload
    );
    return data;
}
