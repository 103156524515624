import dayjs, { Dayjs } from 'dayjs';
import { get } from 'http';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

export type ISimulatorData = {
    temp: number;
    sig: number;
    batext: number;
    batint: number;
    batintvolts: number;
    odometer: number;
    hours: number;
    lat: number;
    lng: number;
    cursor: number;
    speed: number;
    nsat: number;
    gpsTime: string;
    imei: string;
    acc: boolean;
    blk: boolean;
    fix: boolean;
    alertType?: number;
    eventName?: string;
    file?: string;
    isPing?: boolean;
    server: string;
}

type iSimulatorContext = {
    updateConfig: (newConfig: Partial<iSimulatorContext>) => void;
    values: ISimulatorData;
    isSendEvt: boolean;
    evtSelected: string;
    selectedTimezone: string;
    date: Dayjs;
    server: string;
}
const defaultConfig: iSimulatorContext = {
    updateConfig: () => { },
    values: {
        temp: 25, // Temperatura em graus Celsius
        sig: 60, // Força do sinal
        batext: 12.5, // Bateria externa em %
        batint: 95, // Bateria interna em %
        batintvolts: 3.6, // Bateria interna em Volts
        odometer: 120, // Hodômetro em quilômetros
        hours: 6789, // Horímetro em horas de operação
        lat: -23.550520, // Latitude
        lng: -46.633308, // Longitude
        cursor: 175, // Cursor
        speed: 80, // Velocidade em km/h
        nsat: 12, // Número de satélites conectados
        gpsTime: dayjs().toISOString(), // Data e hora do registro
        imei: '1234', // IMEI do dispositivo
        acc: false, // Acelerômetro
        blk: false, // Bloco
        fix: true, // Fixação
        server: 'dev', // Servidor de destino
    },
    isSendEvt: false,
    evtSelected: '',
    selectedTimezone: '0',
    date: dayjs(),
    server: 'dev', // Servidor de destino
};


const SimulatorContext = createContext<iSimulatorContext>(defaultConfig);

export const SimulatorConfig = () => {
    return useContext(SimulatorContext);
};

type SimulatorProviderProps = {
    children: ReactNode;
};

export const SimulatorProvider = ({ children }: SimulatorProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);

    const updateConfig = (newConfig: Partial<iSimulatorContext>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };


    return (
        <SimulatorContext.Provider value={{ ...config, updateConfig }}>
            {children}
        </SimulatorContext.Provider>
    );
};
