import React, { useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CheckboxField from './CheckBoxField';
import { formatDateDayJs } from '../../../utils/Converter';
import { set } from 'date-fns';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { ISimulatorData, SimulatorConfig } from '../utils/simulatorContext';



const Title = styled.h1`
font-size: 24px;
color: #333;
text-align: center;
margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
  align-items: end;
`;

interface BoxProps {
    width?: string;
}

const Box = styled.div<BoxProps>`
    min-width: ${(props) => props.width || '100%'};
`;
const BoxMax = styled.div<BoxProps>`
margin-top: 20px;
    max-width: ${(props) => props.width || '100%'};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #45a049;
  }
`;




const schema = yup.object().shape({
    temp: yup.string().required('O campo "Temp" é obrigatório'),
    sig: yup.string().required('O campo "Sinal" é obrigatório'),
    batext: yup.string().required('O campo "Bat Externa" é obrigatório'),
    batint: yup.string().required('O campo "Bat Interna(%)" é obrigatório'),
    batintvolts: yup.string().required('O campo "Bat Interna(V)" é obrigatório'),
    odometer: yup.string().required('O campo "Hodômetro" é obrigatório'),
    hours: yup.string().required('O campo "Horímetro" é obrigatório'),
    lat: yup.string().required('O campo "Latitude" é obrigatório'),
    lng: yup.string().required('O campo "Longitude" é obrigatório'),
    cursor: yup.string().required('O campo "Cursor" é obrigatório'),
    speed: yup.string().required('O campo "Speed" é obrigatório'),
    nsat: yup.string().required('O campo "N. Sat" é obrigatório'),
    imei: yup.string().required('O campo "IMEI" é obrigatório'),
});


interface SimulatorCommandsProps {
    onOutput: () => void;
}


interface IOption {
    value: string;
    label: string;
}

const optionsSource: IOption[] = [
    { value: 'prod', label: 'Produção' },
    { value: 'dev', label: 'Desenvolvimento' },
];

const optionsTimeZones: IOption[] = [
    { value: '-5', label: 'Timezone -5' },
    { value: '-4', label: 'Timezone -4' },
    { value: '-3', label: 'Timezone -3' },
    { value: '-2', label: 'Timezone -2' },
    { value: '-1', label: 'Timezone -1' },
    { value: '0', label: 'Timezone 0' },
    { value: '1', label: 'Timezone 1' },
    { value: '2', label: 'Timezone 2' },
    { value: '3', label: 'Timezone 3' },
    { value: '4', label: 'Timezone 4' },
    { value: '5', label: 'Timezone 5' },
]

const optionsEvts: IOption[] = [
    { value: 'BATCUT', label: 'Corte de Bateria' },
    { value: 'PANICO', label: 'Pânico' },
    { value: 'SOS', label: 'SOS' },
    { value: 'PANIC', label: 'Pânico Alternativo' },
    { value: 'MOVE', label: 'Movimento' },
    { value: 'GUINCHO', label: 'Guincho' },
    { value: 'DOOR_RIDE', label: 'Porta Aberta Durante Trajeto' },
    { value: 'HARACC', label: 'Aceleração Brusca' },
    { value: 'HARDEC', label: 'Frenagem Brusca' },
    { value: 'HARCURVE', label: 'Curva Brusca' },
    { value: 'DMSEYECLOSED', label: 'Olhos Fechados (Cansaço)' },
    { value: 'DMSYAWN', label: 'Bocejo (Cansaço)' },
    { value: 'DMSDISTRATION', label: 'Distração' },
    { value: 'DMSSMOKING', label: 'Fumando' },
    { value: 'DMSPHONE', label: 'Usando Celular' },
];



const SimulatorCommands: React.FC<SimulatorCommandsProps> = ({ onOutput }) => {
    const { values, date, isSendEvt, evtSelected, selectedTimezone, updateConfig } = SimulatorConfig();
    const formRef = useRef<FormHandles>(null);


    const handleSubmit = async (data: ISimulatorData) => {
        try {
            formRef.current?.setErrors({});
            await schema.validate(data, { abortEarly: false });
            onOutput();
            setToCurrentTime();
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    const setToCurrentTime = () => {
        let dt = dayjs();
        const adjustedDate = dt.add(Number(selectedTimezone), 'hour');
        updateConfig({ date: adjustedDate });
    };


    const handleTimezoneChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const timezoneOffset = parseInt(event.target.value, 10);
        const adjustedDate = dayjs().add(timezoneOffset, 'hour');
        updateConfig({ date: adjustedDate });
    };

    useEffect(() => {
        formRef.current?.setFieldValue('lat', values.lat);
        formRef.current?.setFieldValue('lng', values.lng);
    }, [values.lat, values.lng]);

    return (
        <FormContainer>
            <Title>Simulator</Title>

            <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined} initialData={values}>
                <Row>
                    <Box width="90px">
                        <InputFieldForm label="Temp*" name="temp"
                            onBlur={(e) => {
                                let temp = e.target.value;
                                updateConfig({ values: { ...values, temp: Number(temp) } });
                            }}
                        />
                    </Box>
                    <Box width="90px">
                        <InputFieldForm label="Sinal*" name="sig"
                            onBlur={(e) => {
                                let sig = e.target.value;
                                updateConfig({ values: { ...values, sig: Number(sig) } });
                            }}
                        />
                    </Box>
                    <Box width="90px">
                        <InputFieldForm label="Bat Externa*" name="batext"
                            onBlur={(e) => {
                                let batext = e.target.value;
                                updateConfig({ values: { ...values, batext: Number(batext) } });
                            }}
                        />
                    </Box>
                    <Box width="90px">
                        <InputFieldForm label="Bat Interna(%)*" name="batint"
                            onBlur={(e) => {
                                let batint = e.target.value;
                                updateConfig({ values: { ...values, batint: Number(batint) } });
                            }}
                        />
                    </Box>
                    <Box width="90px">
                        <InputFieldForm label="Bat Interna(V)*" name="batintvolts"
                            onBlur={(e) => {
                                let batintvolts = e.target.value;
                                updateConfig({ values: { ...values, batintvolts: Number(batintvolts) } });
                            }}
                        />
                    </Box>
                </Row>
                <Row>
                    <InputFieldForm label="IMEI*" name="imei"
                        onBlur={(e) => {
                            let imei = e.target.value;
                            updateConfig({ values: { ...values, imei } });
                            localStorage.setItem('imei', imei);
                        }}
                    />
                    <InputFieldForm label="Hodômetro*" name="odometer"
                        onBlur={(e) => {
                            let odometer = e.target.value;
                            updateConfig({ values: { ...values, odometer: Number(odometer) } });
                        }}
                    />
                    <InputFieldForm label="Horimetro*" name="hours"
                        onBlur={(e) => {
                            let hours = e.target.value;
                            updateConfig({ values: { ...values, hours: Number(hours) } });
                        }}
                    />
                </Row>
                <Row>
                    <InputFieldForm label="Latitude*" name="lat" />
                    <InputFieldForm label="Longitude*" name="lng" />
                    <Box width="90px">
                        <InputFieldForm label="Cursor*" name="cursor"
                            onBlur={(e) => {
                                let cursor = e.target.value;
                                updateConfig({ values: { ...values, cursor: Number(cursor) } });
                            }}
                        />
                    </Box>
                    <Box width="90px">
                        <InputFieldForm label="Speed*" name="speed"
                            onBlur={(e) => {
                                let speed = e.target.value;
                                updateConfig({ values: { ...values, speed: Number(speed) } });
                            }}
                        />
                    </Box>
                    <Box width="90px">
                        <InputFieldForm label="N. Sat*" name="nsat"
                            onBlur={(e) => {
                                let nsat = e.target.value;
                                updateConfig({ values: { ...values, nsat: Number(nsat) } });
                            }}
                        />
                    </Box>
                </Row>
                <Row>
                    <CheckboxField
                        name="isPing"
                        label="Ping"
                        onChange={(event) => updateConfig({ values: { ...values, isPing: event.target.checked } })}
                        checked={values.isPing ?? false} />
                    <CheckboxField
                        name="acc"
                        label="Ignição"
                        onChange={(event) => updateConfig({ values: { ...values, acc: event.target.checked } })}
                        checked={values.acc} />
                    <CheckboxField
                        name="fix"
                        label="Gps Fix"
                        onChange={(event) => updateConfig({ values: { ...values, fix: event.target.checked } })}
                        checked={values.fix} />
                    <CheckboxField
                        name="blk"
                        label="Bloqueio"
                        onChange={(event) => updateConfig({ values: { ...values, blk: event.target.checked } })}
                        checked={values.blk} />
                </Row>
                <Row>
                    <BoxMax width="150px">
                        <DatePicker
                            value={date}
                            label="Date do Evento"
                            onChange={(newValue) => updateConfig({ date: newValue ? newValue : dayjs(new Date()) })}
                            format='DD/MM/YYYY'
                        />
                    </BoxMax>
                    <BoxMax width="140px">
                        <TimePicker
                            value={date}
                            views={['hours', 'minutes', 'seconds']}
                            label="Hora do Evento"
                            ampm={false}
                            onChange={(newValue) => updateConfig({ date: newValue ? newValue : dayjs(new Date()) })}
                        /></BoxMax>
                    <Box width="200px">
                        <SelectFieldForm
                            options={optionsTimeZones}
                            label={'TimeZone'}
                            name={'selectedTimezone'}
                            value={selectedTimezone}
                            onChange={handleTimezoneChange}
                        />
                    </Box>

                </Row>
                <Row>
                    <CheckboxField
                        name="send_event"
                        label="Enviar Evento"
                        onChange={(event) => updateConfig({ isSendEvt: event.target.checked })}
                        checked={isSendEvt} />
                </Row>
                {isSendEvt && <>
                    <Row>
                        <SelectFieldForm
                            options={optionsEvts}
                            label={'Evento'}
                            name={'evt'}
                            value={evtSelected}
                            onChange={(e) => updateConfig({ evtSelected: e.target.value })}
                        />
                        <InputFieldForm label="Arquivo de Mídia" name="filestream" />
                    </Row>
                </>}
                <Row>
                    <Box width="200px">
                        <SelectFieldForm
                            options={optionsSource}
                            label={'Servidor'}
                            name={'server'}
                            value={values.server}
                            onChange={(e) => updateConfig({ server: e.target.value })}
                        />
                    </Box>
                    <ActionButtons>
                        <SubmitButton type="submit">Enviar</SubmitButton>
                    </ActionButtons>
                </Row>
            </Form>
        </FormContainer>
    );

}
export default SimulatorCommands;
