import React from "react";
import styled from "styled-components";
import { FaExclamationCircle, FaInfoCircle } from "react-icons/fa"; // Ícones para tipos diferentes

interface MessageProps {
    type: "info" | "error"; // Tipo da mensagem
    message: string; // Mensagem a ser exibida
}

const MessageContainer = styled.div<{ type: "info" | "error" }>`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ type }) => (type === "info" ? "#084298" : "#842029")};
  background-color: ${({ type }) =>
        type === "info" ? "#cfe2ff" : "#f8d7da"};
  border: 1px solid
    ${({ type }) => (type === "info" ? "#b6d4fe" : "#f5c2c7")};
  gap: 10px;
  margin-bottom: 5px;

  svg {
    font-size: 20px;
  }
`;

const MessageComp: React.FC<MessageProps> = ({ type, message }) => {
    const Icon = type === "info" ? FaInfoCircle : FaExclamationCircle;

    return (
        <MessageContainer type={type}>
            <Icon />
            {message}
        </MessageContainer>
    );
};

export default MessageComp;
