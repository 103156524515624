import { httpAxiosV4 } from "../../../gateways/Axios";




export async function getListUsers(filter: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/list/?rule=${filter}`,
        "GET"
    );
    return response;
}
export async function getListUserInVehicle(idvehicle: number, filterUser: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/invehicle?idvehicle=${idvehicle}&filter=${filterUser}`,
        "GET"
    );
    return response;
}

export async function insertUsersInVehicle(idvehicle: number, users: iUserInVehicle[]): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/invehicle?idvehicle=${idvehicle}`,
        "POST",
        users
    );
    return response;
}

export async function createUsers(payload: iUsersSystem): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/create`,
        "POST",
        payload
    );
    return response;
}

export async function updateUsers(id: number, payload: iUsersSystem): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/update/${id}`,
        "PUT",
        payload
    );
    return response;
}

export async function updatePassword(iduser: number, password: string): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/updatepassword`,
        "POST",
        { iduser, password }
    );
    return response;
}


export interface iUserInVehicle {
    iduser: number;
    rule: number;
    idgroup: number;
}







export interface iUsersSystem {
    id: number; // u.id
    idclient: number; // u.idclient
    idempresa: number; // u.idempresa
    idgroup: number; // u.idgroup
    name: string; // u.name
    email: string; // u.email
    phone: string; // u.phone
    login: string; // u.login
    oldLogin?: string; // u.login
    pass: string; // u.pass
    rule: number; // u.rule
    expire_at: string | null; // u.expire_at
}
export interface iUsersData {
    id: number; // u.id
    name: string; // u.name
    email: string; // u.email
    phone: string; // u.phone
    login: string; // u.login
    rule: string; // u.rule
    idgroup: number; // u.idgroup
    clientName: string | null; // c.cliente_nome (pode ser nulo se não houver associação)
    vehicle_count: number; // uv.vehicle_count
}

export interface iUserVehicle {
    id: number; // u.id
    name: string; // u.name
    email: string; // u.email
    phone: string; // u.phone
    login: string; // u.login
    rule: string; // u.rule
    idgroup: number; // u.idgroup
    clientName: string | null; // c.cliente_nome (pode ser nulo se não houver associação)
    selected: boolean; // true se o veículo estiver associado, false caso contrário
};