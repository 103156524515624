

import { httpAxiosV4 } from "../../../gateways/Axios";




export async function createDevice(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `devices/create`,
        "POST",
        payload
    );
    return response;
}

export async function getListDevices(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `devices/list`,
        "GET"
    );
    return response;
}
export async function getListEquipRegistered(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `equip/list-registered`,
        "GET"
    );
    return response;
}
export async function registerEquip(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `equip/register`,
        "POST",
        payload
    );
    return response;
}
export async function saveEquip(payload: any): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `equip/save`,
        "POST",
        payload
    );
    return response;
}







export interface iEquipment {
    id: number;
    equip_model: string;
    equip_producer: string;
    equip_producer_id: number;
    mod_group: string;
    mod_tipo: string;
}



export interface iDevicesExcel {
    id: number
    imei: string
    device_model: string
    protocol: string
    object_owner: string
}


export interface IDevicesDataExcel {
    id: number
    status: string
    equip_imei: string
    equip_status: string
    equip_modelo: number
    equip_marca: number
    equip_modelo_name: string
    equip_idempresa: number
    protocol: string
    LoRaWANId: string
};

export interface IDevicesData {
    id: number
    status: string
    equip_imei: string
    equip_status: string
    equip_modelo: number
    equip_marca: number
    equip_modelo_name: string
    equip_idempresa: number
};
