import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

interface ColumnStatusProps {
    messageType: 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO' | '';
    messageLabel: string;
}

const getMessageColor = (messageType: string) => {
    switch (messageType.toUpperCase()) {
        case 'ERROR':
            return '#FF0000'; // red
        case 'SUCCESS':
            return '#008000'; // green
        case 'WARNING':
            return '#FFA500'; // orange
        case 'INFO':
            return '#0000FF'; // blue
        default:
            return '#808080'; // grey
    }
};

const StyledChip = styled(Chip)<{ messageType: string }>(({ messageType }) => ({
    backgroundColor: getMessageColor(messageType) + '30', // 50% opacity
    color: 'black',
    border: `1px solid ${getMessageColor(messageType)}`,
    height: '20px',
    fontWeight: '500',
}));

const ColumnStatus: React.FC<ColumnStatusProps> = ({ messageType, messageLabel }) => {
    return (
        <>

            <StyledChip label={messageLabel} variant="outlined" messageType={messageType} />

        </>
    );
};

export default ColumnStatus;
