import { Column, SelectColumn } from 'react-data-grid';
import { IDevicesDataExcel } from '../Repository/DevicesRepo';
import ColumnStatus from '../../../components/datagridComp/ColumnStatus';

export const getColumnsDevicesExcel = (
    editItem: (row: IDevicesDataExcel) => void
): Column<IDevicesDataExcel>[] => {
    return [
        // Selection Column
        {
            ...SelectColumn,
            name: 'Select',
        },
        // Device Status Column
        {
            key: 'status',
            name: 'Status',
            width: 200,
            renderCell(props: any) {
                return <ColumnStatus
                    messageLabel={props.row.status}
                    messageType={
                        props.row.status.includes('Erro') ? 'ERROR' :
                            props.row.status.includes('Salvo') ? 'SUCCESS' : ''}
                />;
            },
        },
        // Device IMEI Column
        {
            key: 'equip_imei',
            name: 'IMEI',
            width: 200,
            resizable: true,
        },
        // Device Model Column
        {
            key: 'equip_modelo_name',
            name: 'Modelo',
            width: 200,
            resizable: true,
        },
        // Device Protocol Column
        {
            key: 'protocol',
            name: 'Protocolo',
            width: 200,
            resizable: true,
        },
        // Device LoRaWAN ID Column
        {
            key: 'LoRaWANId',
            name: 'LoRaWAN ID',
            width: 200,
            resizable: true,
        },


    ];
};
