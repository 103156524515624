import { Column, SelectColumn } from 'react-data-grid';
import { BtnCheckBoxOptions } from '../../StylePages';
import { IVehicleData } from '../Repository/VehicleRepo';
import SmallButton from '../../../components/datagridComp/SmallButton';
import { FaShareAlt, FaUser } from 'react-icons/fa';

export const getColumnsVehicles = (
    editItem: (row: IVehicleData) => void,
    openListUser: (row: IVehicleData) => void
): Column<IVehicleData>[] => {
    return [
        // Selection Column
        {
            ...SelectColumn,
            name: 'Select',
        },
        // Action Column
        // {
        //     key: 'edit',
        //     name: 'Actions',
        //     width: 90,
        //     renderCell(props: any) {
        //         return (
        //             <>
        //                 <BtnCheckBox onClick={() => editItem(props.row)}>
        //                     ✏️ Edit
        //                 </BtnCheckBox>
        //             </>
        //         );
        //     },
        // },

        {
            key: 'connected_users_count',
            name: 'Usuários',
            width: 70,
            renderCell(props: any) {
                const isZero = props.row.connected_users_count === 0;
                return (
                    <>
                        <BtnCheckBoxOptions
                            onClick={() => openListUser(props.row)}
                            isZero={isZero} // Passa a prop condicionalmente
                        >
                            <FaShareAlt style={{ marginRight: 5 }} /> {props.row.connected_users_count}
                        </BtnCheckBoxOptions>
                    </>

                );
            },
        },

        // ID Column
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Vehicle Plate Column
        {
            key: 'vehicle_plate',
            name: 'Veículo',
            width: 280,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{props.row.vehicle_plate}</i> / <strong>{props.row.vehicle_model_name}</strong></div>
                    </>

                )
            }
        },
        // Client Name Column
        {
            key: 'client_name',
            name: 'Cliente',
            width: 200,
            resizable: true,
        },


        // Device IMEI Column
        {
            key: 'device_imei',
            name: 'Equipamento',
            width: 220,
            renderCell(props: any) {
                return (
                    props.row.device_imei ? <>
                        <div><i>{props.row.device_imei}</i> / <strong>{props.row.manufacturer_name}-{props.row.model_reference}</strong></div>
                    </> : <></>
                )
            }
        },
        // {
        //     key: 'device_imei',
        //     name: 'Equipamento',
        //     width: 220,
        //     renderCell(props: any) {
        //         return (
        //             props.row.device_imei ? <>
        //                 <div><i>{props.row.device_imei}</i> / <strong>{props.row.manufacturer_name}-{props.row.model_reference}</strong></div>
        //             </> : <SmallButton label='Selecionar Equipamento' onClick={() => {}} />
        //         )
        //     }
        // },

        // SIM Serial Column
        {
            key: 'sim_serial',
            name: 'Chip',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.sim_serial ? <>
                        <div><i>{props.row.sim_serial}</i> / <strong>{props.row.sim_operator}-{props.row.sim_provider_name}</strong></div>
                    </> : <></>
                )
            }
        },
        // {
        //     key: 'sim_serial',
        //     name: 'Chip',
        //     width: 280,
        //     renderCell(props: any) {
        //         return (
        //             props.row.sim_serial ? <>
        //                 <div><i>{props.row.sim_serial}</i> / <strong>{props.row.sim_operator}-{props.row.sim_provider_name}</strong></div>
        //             </> : <SmallButton label='Selecionar Chip' onClick={() => {
        //             }
        //             } />
        //         )
        //     }
        // },
        // Registration Date Column
        {
            key: 'client_registration_date',
            name: 'Registration Date',
            width: 150,
            resizable: true,
        },

        // Time Since Last Transmission Column
        {
            key: 'time_since_last_transmission_in_seconds',
            name: 'Last Transmission (s)',
            width: 200,
            resizable: true,
        },
        // Plan Value Column
        {
            key: 'plan_value',
            name: 'Plan Value',
            width: 120,
            resizable: true,
        },
        // Plan Title Column
        {
            key: 'plan_title',
            name: 'Plan',
            width: 150,
            resizable: true,
        },

        // Vehicle Registration Date Column
        {
            key: 'vehicle_registration_date',
            name: 'Registration',
            width: 150,
            resizable: true,
        },
    ];
};
