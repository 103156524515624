

import React, { useState } from 'react';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerFlexInline, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../Style/StyleDevices';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { handleSearch } from '../../../utils/searchUtils';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import Sidebar from '../../../components/datagridComp/SideBar';
import { getColumnsDevicesList } from '../Utils/DevicesColumns';
import { getListDevices, IDevicesData } from '../Repository/DevicesRepo';



const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';

const DevicesListTab: React.FC = () => {

    const { addNotification } = useNotifications();
    const [ClientEdit, setClientEdit] = React.useState<IDevicesData>();

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<IDevicesData[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);


    const handleEditItem = (row: IDevicesData) => {
        setClientEdit(row);
        setSideContent(EDIT_FORM);
    };


    const columns = getColumnsDevicesList(handleEditItem);


    async function getListInServer() {
        try {
            const result = await getListDevices();
            console.log(result)
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }

    React.useEffect(() => {
        getListInServer();
    }, []);


    const selectAll = () => {
        const allIds = new Set(filteredRows.map((item) => item.id));
        setSelectedRows(allIds);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const handleNewClient = () => {
        setClientEdit(undefined);
        setSideContent(NEW_FORM);
    };

    const handleUpdateList = () => {
        getListInServer();
        CancelFormClient();
    };

    const CancelFormClient = () => {
        setClientEdit(undefined);
        setSideContent(undefined);
    }

    return (
        <>
            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer}>
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                </BtnFaIcon >
                <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleNewClient}>
                    <BoxIcon icon={faAdd} className="fa-icon" /> Criar Cliente
                </BtnFaIcon >
            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}

            {/* <Sidebar width='600px' isOpen={sideContent === NEW_FORM} onClose={CancelFormClient} title={'Cadastro de Cliente'}>
                <FormClients
                    isExcelForm={false}
                    onUpdate={handleUpdateList}
                    onCancel={CancelFormClient} />
            </Sidebar>
            {ClientEdit && <Sidebar width='700px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormClient} title={'Editar cliente'}>
                <FormClients
                    onCancel={CancelFormClient}
                    onEditReturn={handleUpdateList}
                    initialData={ClientEdit}
                    isExcelForm={false}
                />
            </Sidebar>} */}
        </>

    )

}
export default DevicesListTab