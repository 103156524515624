import { Column, SelectColumn } from 'react-data-grid';
import { IFormClientsData } from '../Repository/ClientesRepo';
import { BtnCheckBox } from '../../StylePages';

export const getColumnsClientes = (
    editItem: (row: IFormClientsData) => void
): Column<IFormClientsData>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // {
        //     key: 'edit',
        //     name: 'Ações',
        //     width: 90,
        //     renderCell(props: any) {
        //         return (
        //             <>{/*<BtnCheckBox onClick={() => viewInMap(props.row)}>📂 Abrir</BtnCheckBox>*/}
        //                 <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
        //         )
        //     }
        // },
        // Coluna para o ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para o Nome
        {
            key: 'cliente_nome',
            name: 'Nome',
            width: 200,
            resizable: true,
        },
        // Coluna para o Email
        {
            key: 'cliente_email',
            name: 'Email',
            width: 200,
            resizable: true,
        },
        // Coluna para o CPF
        {
            key: 'cliente_cpf',
            name: 'CPF',
            width: 150,
            resizable: true,
        },
        // Coluna para o Telefone 1
        {
            key: 'cliente_tel1',
            name: 'Telefone 1',
            width: 150,
            resizable: true,
        },
        // Coluna para o Endereço
        {
            key: 'cliente_ender',
            name: 'Endereço',
            width: 200,
            resizable: true,
        },
        // Coluna para o Bairro
        {
            key: 'cliente_bairro',
            name: 'Bairro',
            width: 150,
            resizable: true,
        },
        // Coluna para o Número
        {
            key: 'cliente_numero',
            name: 'Número',
            width: 100,
            resizable: true,
        },
        // Coluna para o CEP
        {
            key: 'cliente_cep',
            name: 'CEP',
            width: 100,
            resizable: true,
        },
        // Coluna para a Cidade
        {
            key: 'cliente_cidade',
            name: 'Cidade',
            width: 150,
            resizable: true,
        },
        // Coluna para o Estado
        {
            key: 'cliente_uf',
            name: 'UF',
            width: 50,
            resizable: true,
        },

    ];
};

