import React, { useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { AuthConfig } from '../../../Config/AuthContext';
import { createUsers, iUsersData, iUsersSystem, updatePassword, updateUsers } from '../Repository/UserRepo';
import Select from 'react-select';
import { StorageConfig } from '../../../Config/StorageContext';
import { CancelButton, ContainerInlinePicker, InfoBoxLabel, Label, MarginTop, Spinner, SubmitButton } from '../../StylePages';
import { extractErrorMessage } from '../../../utils/CustomErros';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { dtExpireInDays } from '../../../utils/Converter';
interface IOption {
    value: string;
    label: string;
}

const optionsEmpresaType: IOption[] = [
    { value: '3', label: 'Usuário' },
    { value: '2', label: 'Administrador' },
];

const Title = styled.h1`
font-size: 24px;
color: #333;
text-align: center;
margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; /* Ocupa a largura total do componente pai */
  margin-top: 5px;
  margin-bottom: 5px;
`;


interface BoxProps {
    width?: string;
}

const Box = styled.div<BoxProps>`
    min-width: ${(props) => props.width || '100%'};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;




const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  margin-top: 4px;
`;

const userSchema = yup.object().shape({
    name: yup
        .string()
        .required('O nome completo é obrigatório'),
    email: yup
        .string()
        .email('E-mail inválido')
        .required('O e-mail é obrigatório'),
    phone: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(
            /^\d{10,11}$/,
            'O telefone deve conter 10 ou 11 dígitos numéricos'
        ),
    login: yup
        .string()
        .required('O login é obrigatório'),
    pass: yup
        .string()
        .required('A senha é obrigatória')
        .min(4, 'A senha deve ter pelo menos 4 caracteres'),
    password_confirme: yup
        .string()
        .oneOf([yup.ref('pass')], 'As senhas devem ser iguais')
        .required('A confirmação de senha é obrigatória')
});
const userUpdateSchema = yup.object().shape({
    name: yup
        .string()
        .required('O nome completo é obrigatório'),
    email: yup
        .string()
        .email('E-mail inválido')
        .required('O e-mail é obrigatório'),
    phone: yup
        .string()
        .required('O telefone é obrigatório')
        .matches(
            /^\d{10,11}$/,
            'O telefone deve conter 10 ou 11 dígitos numéricos'
        ),
    login: yup
        .string()
        .required('O login é obrigatório')
});

const passwordSchema = yup.object().shape({
    pass: yup
        .string()
        .required('A senha é obrigatória')
        .min(4, 'A senha deve ter pelo menos 4 caracteres'),
    password_confirme: yup
        .string()
        .oneOf([yup.ref('pass')], 'As senhas devem ser iguais')
        .required('A confirmação de senha é obrigatória')
});


interface FormProps {
    initialData?: iUsersSystem;
    onCancel: () => void;
    onUpdate?: () => void;
    onEditReturn?: (value: iUsersData) => void;
    uuid: string;
}

const FormUsers: React.FC<FormProps> = ({ initialData, onUpdate, onCancel, uuid }) => {
    const { clients, syncClients } = StorageConfig();
    const formRef = useRef<FormHandles>(null);
    const [rule, setRule] = React.useState('2');
    const { addNotification } = useNotifications();
    const [clientSelected, setClientSelected] = React.useState<IOption | null>(null);
    const [clientList, setClientOptions] = React.useState<IOption[]>();
    const { user } = AuthConfig();
    const [error, setError] = React.useState<string | null>(null);
    const [errorApi, setErrorApi] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dtExpire, setDtExpire] = React.useState<Dayjs | null>(dtExpireInDays(30));
    const [showDtExpire, setShowDtExpire] = React.useState<boolean>(false);
    const [showRedefinedPassword, setShowRedefinedPassword] = React.useState<boolean>(false);
    const [showSyncClient, setShowSyncClient] = React.useState<boolean>(true);

    const sendToServer = async (payload: iUsersSystem): Promise<any> => {
        try {
            setIsLoading(true);
            payload.id ? await updateUsers(payload.id, payload) : await createUsers(payload);
            let msg = payload.id ? 'Usuário atualizado com sucesso' : 'Usuário criado com sucesso';
            let title = payload.id ? 'Usuário Atualizado' : 'Usuário Criado';
            addNotification(title, msg, 'success');
            formRef.current?.reset();
            onUpdate?.();
        } catch (error: any) {
            console.log(error);
            let message = extractErrorMessage(error)
            setErrorApi(message);
        } finally {
            setIsLoading(false);
        }

    };
    const sendNewPasswordToServer = async (payload: any): Promise<any> => {
        try {
            setIsLoading(true);
            await updatePassword(payload.id, payload.pass)
            addNotification('Senha Atualizada', 'Senha atualizada com sucesso', 'success');
            formRef.current?.reset();
            onUpdate?.();
        } catch (error: any) {
            console.log(error);
            let message = extractErrorMessage(error)
            setErrorApi(message);
        } finally {
            setIsLoading(false);
        }

    };

    const handleSubmit = async (data: iUsersSystem) => {
        try {
            formRef.current?.setErrors({});
            data.id = initialData?.id || 0;
            initialData ? await userUpdateSchema.validate(data, { abortEarly: false })
                : await userSchema.validate(data, { abortEarly: false });
            if (rule === '3' && showSyncClient && !clientSelected) {
                setError('Selecione um cliente para criar um usuário');
                return;
            }
            let idclient = rule === '2' ? user?.idempresa :
                showSyncClient ? parseInt(clientSelected?.value || '0') : 0;
            let payload: iUsersSystem = {
                id: data.id,
                idclient: idclient || 0,
                idempresa: user?.idempresa || 0,
                idgroup: initialData?.idgroup || 0,
                name: data.name,
                email: data.email,
                phone: data.phone,
                login: data.login,
                oldLogin: initialData?.oldLogin || '',
                pass: data.pass,
                rule: data.rule,
                expire_at: showDtExpire ? dtExpire?.format('YYYY-MM-DD HH:mm:ss') || null : null
            };
            sendToServer(payload);
        } catch (err) {
            console.log(err)
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    const handleSavePassword = async (data: any) => {
        try {
            formRef.current?.setErrors({});
            await passwordSchema.validate(data, { abortEarly: false })
            let payload: any = {
                id: initialData?.id || 0,
                pass: data.pass,
            };
            sendNewPasswordToServer(payload);
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    const handleChange = (selected: IOption | null) => {
        setError(null);
        setClientSelected(selected);
    };

    const loadClients = async () => {
        const clientsLocal = clients.length === 0 ? await syncClients() : clients
        const options: IOption[] = clientsLocal.map(client => ({
            value: client.id.toString(),
            label: `[${client.id}] ${client.name}`
        }))
        setClientOptions(options)
    }

    const recoverInitialData = async () => {
        setRule(initialData?.rule.toString() || '2')
        setDtExpire(dayjs(initialData?.expire_at || dtExpireInDays(30)))
        setShowDtExpire(initialData?.expire_at ? true : false)
        if (initialData) {
            initialData.oldLogin = initialData.login ?? '';
        }
        if (initialData?.rule === 3) {
            let client = clients.find(client => client.id === initialData.idclient)
            console.log('client', client)
            if (client) {
                setClientSelected({ value: client.id.toString(), label: `[${client.id}] ${client.name}` })
            }
        }

    }



    React.useEffect(() => {
        loadClients()
        setErrorApi(null)
        if (initialData) {
            recoverInitialData()
        } else {
            formRef.current?.reset()
            setRule('2')
            setClientSelected(null)
            setShowDtExpire(false)
            setDtExpire(dtExpireInDays(30))
        }
        formRef.current?.setErrors({})
    }, [uuid])



    return (
        <>{showRedefinedPassword ?
            <FormContainer>
                <Title>Redefinir Senha</Title>
                <Form ref={formRef} onSubmit={handleSavePassword} placeholder={''}>
                    <Row>
                        <InputFieldForm label="Senha*" name="pass" type="password" />
                        <InputFieldForm label="Confirme a Senha*" name="password_confirme" type="password" />
                    </Row>
                    <ActionButtons>
                        <CancelButton type="button" onClick={() => setShowRedefinedPassword(false)}>Cancelar</CancelButton>
                        <SubmitButton type="submit" isLoading={isLoading} disabled={isLoading}>
                            {isLoading ? <>
                                <Spinner />
                                Salvando, aguarde...
                            </> : "Salvar"}
                        </SubmitButton>
                    </ActionButtons>
                </Form>
            </FormContainer> :
            <FormContainer>
                <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData} placeholder={''}>
                    <Row>
                        <InputFieldForm label="Nome Completo*" name="name" /> {/* Alterado de razaoSocial para cliente_nome */}
                        <SelectFieldForm
                            options={optionsEmpresaType}
                            label="Tipo de Usuário*"
                            name="rule" // Alterado de empresa_tipo para cliente_tipo
                            value={rule}
                            onChange={(e) => setRule(e.target.value)}
                        />
                    </Row>
                    <Row>
                        <InputFieldForm label="E-mail*" name="email" type="email" /> {/* Alterado de email para cliente_email */}
                        <InputFieldForm label="WhatsApp/Telefone*" name="phone" /> {/* Alterado de tel1 para cliente_tel1 */}
                    </Row>
                    <Row>
                        <InputFieldForm label="Login*" name="login" />
                        {!initialData && <>
                            <InputFieldForm label="Senha*" name="pass" type="password" />
                            <InputFieldForm label="Confirme a Senha*" name="password_confirme" type="password" />
                        </>}
                    </Row>
                    {rule === '3' && <>
                        <MarginTop />
                        <Row>
                            <InfoBoxLabel>
                                <input
                                    type="checkbox"
                                    checked={showSyncClient}
                                    onChange={(e) => setShowSyncClient(e.target.checked)}

                                />
                                Vincular a um cliente?
                            </InfoBoxLabel>
                        </Row>
                        {showSyncClient && <Row>
                            <Wrapper>
                                <Label>{'Selecione um cliente'}</Label>
                                <Select
                                    options={clientList}
                                    value={clientSelected}
                                    onChange={handleChange}
                                    isSearchable
                                    placeholder="Pesquise um cliente"
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Wrapper>
                        </Row>}
                    </>}
                    <MarginTop />
                    <Row>
                        <InfoBoxLabel>
                            <input
                                type="checkbox"
                                checked={showDtExpire}
                                onChange={(e) => setShowDtExpire(e.target.checked)}

                            />
                            Data de Expiração de acesso
                        </InfoBoxLabel>
                    </Row>
                    {showDtExpire && <Row>
                        <ContainerInlinePicker>
                            <DatePicker
                                value={dtExpire}
                                label="Data da Expiração"
                                onChange={(newValue) => setDtExpire(newValue)}
                                format='DD/MM/YYYY'
                            />
                            <TimeField
                                value={dtExpire}
                                label="Hora da Expiração"
                                onChange={(newValue) => setDtExpire(newValue)}
                                format="HH:mm:ss"
                            />
                        </ContainerInlinePicker>
                    </Row>}
                    {errorApi && <Row>
                        <ErrorMessage>{errorApi}</ErrorMessage>
                    </Row>}
                    <ActionButtons>
                        <CancelButton type="button" onClick={onCancel}>Cancelar</CancelButton>
                        <SubmitButton type="submit" isLoading={isLoading} disabled={isLoading}>
                            {isLoading ? <>
                                <Spinner />
                                Salvando, aguarde...
                            </> : "Salvar"}
                        </SubmitButton>
                        {initialData && <CancelButton type="button" onClick={() => setShowRedefinedPassword(true)}>Redefinir Senha</CancelButton>}
                    </ActionButtons>
                </Form>

            </FormContainer>
        }
        </>);
};

export default FormUsers;


