

import { httpAxiosV4 } from "../../../gateways/Axios";




export async function getListVehicles(): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `vehicles/company`,
        "GET"
    );
    return response;
}


export async function getListVehiclesInUser(idUser: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `user/vehicles?iduser=${idUser}`,
        "GET"
    );
    return response;
}

export async function insertVehiclesInUser(idUser: number, rule: number, idVehicles: number[], showOnlyActives: boolean): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `vehicle/inuser?iduser=${idUser}&rule=${rule}&showonlyactives=${showOnlyActives}`,
        "POST",
        idVehicles
    );
    return response;
}

export async function accessAllVehicle(idUser: number, rule: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `vehicle/allaccess?iduser=${idUser}&rule=${rule}`,
        "POST"
    );
    return response;
}


export async function removeAllVehicle(idUser: number, rule: number): Promise<any> {
    const { data: response } = await httpAxiosV4<any, any>(
        `vehicle/removeall?iduser=${idUser}&rule=${rule}`,
        "POST"
    );
    return response;
}







export interface IVehicleData {
    client_name: string;
    client_registration_date: string; // ISO 8601 date string
    device_imei: string;
    last_transmission: string | null; // ISO 8601 date string or null if no transmission
    vehicle_plate: string;
    vehicle_model_name: string;
    sim_serial: string;
    sim_operator: string;
    sim_provider_name: string;
    model_reference: string;
    manufacturer_name: string;
    time_since_last_transmission_in_seconds: number | null;
    plan_value: number;
    plan_title: string;
    vehicle_registration_date: string; // ISO 8601 date string
    id: number;
    connected_users_count: number;
};
export interface IVehicleInUser {
    id: number; // ID do veículo
    manufacturer: string; // Nome da marca do veículo
    model: string; // Nome do modelo do veículo
    placa: string; // Placa do veículo
    apelido: string | null; // Apelido no painel, pode ser nulo
    selected: boolean; // Indica se o veículo está associado ao usuário
};


