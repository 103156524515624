



import React, { useEffect } from 'react';
import { ContainerSearch, BoxIcon, BtnFaIcon, ContainerInfo, ContainerTable, InfoBox, InfoBoxLabel, SearchAndSelectContainer, Container } from '../../StylePages';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { faClipboardCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { handleSearch } from '../../../utils/searchUtils';
import NoData from '../../../components/NoData';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { accessAllVehicle, getListVehiclesInUser, insertVehiclesInUser, IVehicleInUser, removeAllVehicle } from '../Repository/VehicleRepo';
import { getColumnsVehiclesInUser } from '../Utils/VehicleInUserColumns';
import ButtonSmall from '../../../components/datagridComp/ButtonsSmall';
import MessageComp from '../../../components/datagridComp/MessageComp';

interface Props {
    idUser: number;
    rule: number;
    idgroup: number
    onUpdated?: () => void;
}

let oldSelectedRows: ReadonlySet<number> = new Set();

const ListVehiclesInUser: React.FC<Props> = ({ idUser, rule, idgroup, onUpdated }) => {

    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [rows, setRows] = React.useState<IVehicleInUser[]>([]);
    const [message, setMessage] = React.useState<string | undefined>('Carregando dados...');
    const { addNotification } = useNotifications();
    const [showOnlySelected, setShowOnlySelected] = React.useState<boolean>(false);
    const [activeOnlySelected, setActiveOnlySelected] = React.useState<boolean>(idgroup === 2 ? true : false);
    const [messageInfo, setMessageInfo] = React.useState<string | undefined>();
    const [actionBtn, setActionBtn] = React.useState<boolean>(false);


    const columns = getColumnsVehiclesInUser();

    const filteredRows = React.useMemo(() => {
        let filtered = handleSearch(rows, findInputTerms);
        if (showOnlySelected) {
            filtered = filtered.filter((row) => selectedRows.has(row.id));
        }
        return filtered;
    }, [findInputTerms, rows, selectedRows, showOnlySelected]);

    useEffect(() => {
        let msg = undefined;
        let action = false;


        if (selectedRows.size === 0 && oldSelectedRows.size > 0) {
            msg = 'Para desvincular todos os veículos, utilize o botão "Remover Todos"';
            action = true;
        }

        if (selectedRows.size === rows.length && rule === 2) {
            msg = 'Para permitir acesso a todos os veículos, utilize o botão "Acesso a Todos Veículos"';
            action = true;
        }
        setActionBtn(action);
        setMessageInfo(msg);

    }, [selectedRows]);

    const getListInServer = async () => {
        try {
            setMessage('Carregando dados...');
            const result = await getListVehiclesInUser(idUser);
            setRows(result);
            const selected: Set<number> = new Set(
                result.filter((row: { selected: boolean }) => row.selected).map((row: { id: number }) => row.id)
            );
            oldSelectedRows = selected;
            setSelectedRows(selected);
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    };

    const handleSave = async () => {
        try {
            setMessage('Salvando dados...');
            const idvehicles = Array.from(selectedRows);
            await insertVehiclesInUser(idUser, rule, idvehicles, activeOnlySelected);
            addNotification('', 'Veículos associados ao usuário com sucesso', 'success');
            onUpdated?.();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    }

    const setServerAction = async (type: string) => {
        try {
            let msg = type === 'add' ? 'Adicionando veículos...' : 'Removendo veículos...';
            setMessage(msg);
            type === 'add' ? await accessAllVehicle(idUser, rule) : await removeAllVehicle(idUser, rule)
            addNotification('', 'Veículos associados ao usuário com sucesso', 'success');
            onUpdated?.();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    }

    const handleRemoveAll = () => {
        setServerAction('remove');
    }

    const handleAddAll = () => {
        setServerAction('add');
    }


    useEffect(() => {
        getListInServer();
    }, []);

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> :
                <Container>
                    <SearchAndSelectContainer>
                        <ContainerSearch>
                            <SearchFieldV2
                                onSearch={setFindInputTerms}
                                searchTerm={findInputTerms}
                                height={"32px"} />
                        </ContainerSearch>
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        {!actionBtn && <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleSave} >
                            <BoxIcon icon={faClipboardCheck} className="fa-icon" /> Salvar
                        </BtnFaIcon >}
                        {rule === 3 && <ButtonSmall label="Remover Todos" onClick={handleRemoveAll} color={'red'} />}
                        {rule === 2 && <ButtonSmall label="Acesso a Todos Veículos" onClick={handleAddAll} color={'green'} />}
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                        <InfoBoxLabel>
                            <input
                                type="checkbox"
                                checked={showOnlySelected}
                                onChange={(e) => setShowOnlySelected(e.target.checked)}

                            />
                            Exibir somente Selecionados
                        </InfoBoxLabel>
                        {rule === 3 && <InfoBoxLabel>
                            <input
                                type="checkbox"
                                checked={activeOnlySelected}
                                onChange={(e) => setActiveOnlySelected(e.target.checked)}

                            />
                            Liberar Somente Ativos
                        </InfoBoxLabel>}

                    </ContainerInfo>
                    {messageInfo !== undefined ? <MessageComp type="info" message={messageInfo} /> : null}
                    {filteredRows.length === 0 ? <NoData message="Nenhum Registro Encontrado" />
                        : (<>
                            <ContainerTable height='81vh'>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columns}
                                    selectedRows={selectedRows}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable>
                        </>)}

                </Container>
            }
        </>
    )

}
export default ListVehiclesInUser