import { Column, SelectColumn } from 'react-data-grid';
import { IVehicleInUser } from '../Repository/VehicleRepo';

export const getColumnsVehiclesInUser = (
): Column<IVehicleInUser>[] => {
    return [
        // Selection Column
        {
            ...SelectColumn,
            name: 'Select',
        },
        // ID Column
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Client Name Column
        {
            key: 'client_name',
            name: 'Cliente',
            width: 200,
        },
        {
            key: 'placa',
            name: 'Placa',
            width: 280,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{props.row.placa}</i> / <strong>{props.row.apelido}</strong></div>
                    </>

                )
            }
        },
        // Vehicle Plate Column
        {
            key: 'manufacturer',
            name: 'Veículo',
            width: 300,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{props.row.manufacturer}</i> / <strong>{props.row.model}</strong></div>
                    </>

                )
            }
        },



    ];
};
