import { Column, SelectColumn } from 'react-data-grid';
import { IFormClientsData } from '../Repository/ClientesRepo';
import { BtnCheckBox } from '../../StylePages';
import ColumnStatus from '../../../components/datagridComp/ColumnStatus';

export const getColumnsClientesExcel = (
    editItem: (row: IFormClientsData) => void
): Column<IFormClientsData>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // Coluna para ações
        {
            key: 'status',
            name: 'Status',
            width: 90,
            renderCell(props: any) {
                return <ColumnStatus
                    messageLabel={props.row.status}
                    messageType={
                        props.row.status.includes('Erro') ? 'ERROR' :
                            props.row.status.includes('Salvo') ? 'SUCCESS' : ''}
                />;
            },
        },
        {
            key: 'edit',
            name: 'Ações',
            width: 90,
            renderCell(props: any) {
                return (
                    <>{/*<BtnCheckBox onClick={() => viewInMap(props.row)}>📂 Abrir</BtnCheckBox>*/}
                        <BtnCheckBox onClick={() => editItem(props.row)}>✏️ Editar</BtnCheckBox></>
                )
            }
        },
        // Coluna para o ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para o Login
        {
            key: 'login',
            name: 'Login',
            width: 200,
            resizable: true,
        },
        // Coluna para a Senha
        {
            key: 'password',
            name: 'Senha',
            width: 100,
            resizable: true,
        },
        // Coluna para o Nome
        {
            key: 'cliente_nome',
            name: 'Nome',
            width: 200,
            resizable: true,
        },
        // Coluna para o Email
        {
            key: 'cliente_email',
            name: 'Email',
            width: 200,
            resizable: true,
        },
        // Coluna para o CPF
        {
            key: 'cliente_cpf',
            name: 'CPF',
            width: 150,
            resizable: true,
        },
        // Coluna para o RG
        {
            key: 'cliente_rg',
            name: 'RG',
            width: 150,
            resizable: true,
        },
        // Coluna para o Telefone 1
        {
            key: 'cliente_tel1',
            name: 'Telefone 1',
            width: 150,
            resizable: true,
        },
        // Coluna para o Telefone 2
        {
            key: 'cliente_tel2',
            name: 'Telefone 2',
            width: 150,
            resizable: true,
        },
        // Coluna para o Endereço
        {
            key: 'cliente_ender',
            name: 'Endereço',
            width: 200,
            resizable: true,
        },
        // Coluna para o Bairro
        {
            key: 'cliente_bairro',
            name: 'Bairro',
            width: 150,
            resizable: true,
        },
        // Coluna para o Número
        {
            key: 'cliente_numero',
            name: 'Número',
            width: 100,
            resizable: true,
        },
        // Coluna para o CEP
        {
            key: 'cliente_cep',
            name: 'CEP',
            width: 100,
            resizable: true,
        },
        // Coluna para a Cidade
        {
            key: 'cliente_cidade',
            name: 'Cidade',
            width: 150,
            resizable: true,
        },
        // Coluna para o Estado
        {
            key: 'cliente_uf',
            name: 'UF',
            width: 50,
            resizable: true,
        },


    ];
};

