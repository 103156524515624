import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "styled-components";




export const ContainerFlexInline = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
`;



export const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
`;

export const ContainerBarTop = styled.div`
padding: 6px;
display: flex;
    justify-content: space-between;
`

export const ContainerSearch = styled.div`
  width:250px;
`
export const ContainerInfo = styled.div`
padding: 6px;
display: flex;
    justify-content: left;
`

export const ContainerSpan = styled.div`
padding: 6px;
`


export const ContainerTable = styled.div`
padding: 6px;
    overflow: auto;
    
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 2px;
  padding: 4px;
  //height: calc(100vh - 220px);
  overflow: auto;
`;

export const BtnCheckBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

export const InfoBox = styled.span`
  margin-right: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;


// export const BtnFaIcon = styled.span`
//   margin-right: 8px;
//   font-size: 12px;
//   font-weight: 700;
//   cursor: pointer;
//   display: inline-flex;
//   align-items: center;
//   gap: 4px; // Adiciona espaço entre o ícone e o texto
// `;

type BtnFaIconProps = {
  iconColor?: string; // Permite que a cor seja opcional
};

export const BtnFaIcon = styled.span<BtnFaIconProps>`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px; 
  color: ${({ iconColor }) => iconColor || 'green'};

  .fa-icon { // Assume que você adicionará esta classe ao seu ícone FontAwesome
    color: ${({ iconColor }) => iconColor || 'inherit'}; // Usa a cor passada, ou herda se não for especificada
  }
`;

export const BoxIcon = styled(FontAwesomeIcon) <{ iconColor?: string }>`
  color: ${({ iconColor }) => iconColor || 'green'};
  font-size: 16px;
`;

export const PriorityItem = styled.span<{ level: number }>`
  /*background-color: ${({ level }) => (level === 1 ? 'lightgreen' : level === 2 ? 'yellow' : 'red')};*/
  /*color: ${({ level }) => (level === 3 ? 'white' : 'black')};*/
  color: black;
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;

export const PointsItem = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline' : 'none')};
  padding: 2px 4px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
    font-weight: 700;
    cursor: pointer;
`;
