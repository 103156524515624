import React, { useRef, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import styled from 'styled-components';
import InputFieldForm from '../../../components/datagridComp/InputFieldForm';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { useNotifications } from '../../../Notifications/NotificationContext';
import axios from 'axios';
import { AuthConfig } from '../../../Config/AuthContext';
import { createClient, iClients, IFormClientsData, updateClient } from '../Repository/ClientesRepo';

interface IOption {
    value: string;
    label: string;
}

const optionsEmpresaType: IOption[] = [
    { value: '1', label: 'Pessoa Física' },
    { value: '2', label: 'Pessoa Jurídica' },
];

const Title = styled.h1`
font-size: 24px;
color: #333;
text-align: center;
margin-bottom: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
margin: 0 auto;
`;

const Row = styled.div`
  display: flex;
  gap: 16px;
`;

interface BoxProps {
    width?: string;
}

const Box = styled.div<BoxProps>`
    min-width: ${(props) => props.width || '100%'};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #45a049;
  }
`;

const CancelButton = styled.button`
  padding: 12px 24px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #e53935;
  }
`;


const schema = yup.object().shape({
    cliente_tipo: yup.string().required('O tipo de cliente é obrigatório'),
    cliente_cpf: yup
        .string()
        .required('CPF ou CNPJ é obrigatório')
        .test(
            'cpf_cnpj',
            'CPF ou CNPJ inválido',
            (value, context) =>
                context.parent.cliente_tipo === '1'
                    ? cpf.isValid(value || '')
                    : cnpj.isValid(value || '')
        ),
    cliente_nome: yup.string().required('O nome completo é obrigatório'),
    cliente_rg: yup.string().optional(),
    cliente_ender: yup.string().required('O endereço é obrigatório'),
    cliente_numero: yup.string().required('O número é obrigatório'),
    cliente_bairro: yup.string().required('O bairro é obrigatório'),
    cliente_cidade: yup.string().required('A cidade é obrigatória'),
    cliente_uf: yup.string().required('O estado é obrigatório'),
    cliente_cep: yup.string().required('O CEP é obrigatório'),
    cliente_email: yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
    cliente_tel1: yup.string().required('O WhatsApp é obrigatório'),
    cliente_tel2: yup.string().optional(),
    login: yup.string().required('O login é obrigatório'),
    password: yup
        .string()
        .required('A senha é obrigatória')
        .min(4, 'A senha deve ter pelo menos 4 caracteres'),
    password_confirme: yup
        .string()
        .oneOf([yup.ref('password')], 'As senhas devem ser iguais')
        .required('A confirmação de senha é obrigatória'),
});

const schemaEdit = yup.object().shape({
    cliente_tipo: yup.string().required('O tipo de cliente é obrigatório'),
    cliente_cpf: yup
        .string()
        .required('CPF ou CNPJ é obrigatório')
        .test(
            'cpf_cnpj',
            'CPF ou CNPJ inválido',
            (value, context) =>
                context.parent.cliente_tipo === '1'
                    ? cpf.isValid(value || '')
                    : cnpj.isValid(value || '')
        ),
    cliente_nome: yup.string().required('O nome completo é obrigatório'),
    cliente_rg: yup.string().optional(),
    cliente_ender: yup.string().required('O endereço é obrigatório'),
    cliente_numero: yup.string().required('O número é obrigatório'),
    cliente_bairro: yup.string().required('O bairro é obrigatório'),
    cliente_cidade: yup.string().required('A cidade é obrigatória'),
    cliente_uf: yup.string().required('O estado é obrigatório'),
    cliente_cep: yup.string().required('O CEP é obrigatório'),
    cliente_email: yup.string().email('E-mail inválido').required('O e-mail é obrigatório'),
    cliente_tel1: yup.string().required('O WhatsApp é obrigatório'),
    cliente_tel2: yup.string().optional()
});


interface FormProps {
    initialData?: IFormClientsData;
    onCancel: () => void;
    onUpdate?: () => void;
    onEditReturn?: (value: IFormClientsData) => void;
    isExcelForm: boolean;
}

const FormClients: React.FC<FormProps> = ({ initialData, onUpdate, onEditReturn, onCancel, isExcelForm }) => {
    const formRef = useRef<FormHandles>(null);
    const [empresaType, setEmpresaType] = React.useState('2');
    const { addNotification } = useNotifications();
    const { user } = AuthConfig();

    const sendToServer = async (payload: IFormClientsData): Promise<any> => {
        try {
            payload.id ? await updateClient(payload) : await createClient(payload);
            let msg = payload.id ? 'Cliente atualizado com sucesso' : 'Cliente criado com sucesso';
            let title = payload.id ? 'Cliente Atualizado' : 'Cliente Criado';
            addNotification(title, msg, 'success');
            formRef.current?.reset();
            onUpdate?.();
        } catch (error: any) {
            let message =
                "Erro ao realizar a operação. Por favor, tente novamente mais tarde.";
            if (axios.isAxiosError(error)) {
                message = error.response?.data.message || message;
            } else if (error instanceof Error) {
                message = error.message;
            }
            addNotification('Erro ao executar essa ação', message, 'error');

        }
    };

    const handleSubmit = async (data: IFormClientsData) => {
        try {
            formRef.current?.setErrors({});
            if (isExcelForm) {
                data.id = initialData?.id || 0;
                onEditReturn?.(data);
                return;
            }

            data.id = initialData?.id || 0;
            data.cliente_idempresa = user?.idempresa || 0;

            data.id ? await schemaEdit.validate(data, { abortEarly: false }) : await schema.validate(data, { abortEarly: false });
            if (empresaType === '1') {
                if (!cpf.isValid(data.cliente_cpf)) {
                    formRef.current?.setErrors({ cnpj: 'CPF inválido' });
                    return;
                }
            } else {
                if (!cnpj.isValid(data.cliente_cpf)) {
                    formRef.current?.setErrors({ cnpj: 'CNPJ inválido' });
                    return;
                }
            }
            sendToServer(data);
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                err.inner.forEach(error => {
                    if (error.path) {
                        validationErrors[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(validationErrors);
            }
        }
    };

    return (
        <FormContainer>
            <Title>Registrar Cliente</Title>

            <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData} placeholder={''}>
                <Row>
                    <SelectFieldForm
                        options={optionsEmpresaType}
                        label="Tipo de Cliente"
                        name="cliente_tipo" // Alterado de empresa_tipo para cliente_tipo
                        value={empresaType}
                        onChange={(e) => setEmpresaType(e.target.value)}
                    />
                    <InputFieldForm
                        label={empresaType === '1' ? "CPF*" : "CNPJ*"}
                        name="cliente_cpf" // Alterado de cnpj para cliente_cpf
                        placeholder={empresaType === '1' ? "CPF" : "CNPJ"}
                        type="text"
                    />
                </Row>
                <Row>
                    <InputFieldForm label="Nome Completo*" name="cliente_nome" /> {/* Alterado de razaoSocial para cliente_nome */}
                    <InputFieldForm label="RG" name="cliente_rg" /> {/* Adicionado cliente_rg como campo opcional */}
                </Row>
                <Row>
                    <InputFieldForm label="Endereço*" name="cliente_ender" /> {/* Alterado de ender para cliente_ender */}
                    <Box width="50px">
                        <InputFieldForm label="Número*" name="cliente_numero" /> {/* Alterado de num para cliente_numero */}
                    </Box>
                </Row>
                <Row>
                    <InputFieldForm label="Bairro*" name="cliente_bairro" /> {/* Alterado de bairro para cliente_bairro */}
                    <InputFieldForm label="Cidade*" name="cliente_cidade" /> {/* Alterado de cidade para cliente_cidade */}
                    <Box width="75px">
                        <InputFieldForm label="Estado*" name="cliente_uf" /> {/* Alterado de uf para cliente_uf */}
                    </Box>
                    <Box width="120px">
                        <InputFieldForm label="CEP*" name="cliente_cep" /> {/* Alterado de cep para cliente_cep */}
                    </Box>
                </Row>
                <Row>
                    <InputFieldForm label="E-mail*" name="cliente_email" type="email" /> {/* Alterado de email para cliente_email */}
                    <InputFieldForm label="WhatsApp*" name="cliente_tel1" /> {/* Alterado de tel1 para cliente_tel1 */}
                    <InputFieldForm label="Telefone" name="cliente_tel2" /> {/* Alterado de tel2 para cliente_tel2 */}
                </Row>
                {!initialData && <Row>
                    <InputFieldForm label="Login*" name="login" />
                    <InputFieldForm label="Senha*" name="password" type="password" />
                    <InputFieldForm label="Confirme a Senha*" name="password_confirme" type="password" />
                </Row>}
                <ActionButtons>
                    <SubmitButton type="submit">Salvar</SubmitButton>
                    <CancelButton type="button" onClick={onCancel}>Cancelar</CancelButton>
                </ActionButtons>
            </Form>

        </FormContainer>
    );
};

export default FormClients;
