import React, { useState } from 'react';
import styled from 'styled-components';
import Drawer from './Drawer/Drawer';
import RoutesComp from './routes';
import { DrawerConfig } from './Drawer/drawerContext';
// Componente que renderiza as rotas

// Container geral do layout
const LayoutContainer = styled.div`
  display: flex;
  height: 94vh;
`;

// Menu lateral (drawer) ajustável
const DrawerContainer = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? '190px' : '60px')}; /* Ajuste de largura quando aberto ou fechado */
  transition: width 0.3s;
  background-color: #f1f5f9;
  display: flex;
  flex-direction: column;
`;

// Conteúdo principal que se ajusta com base na largura do menu
const ContentContainer = styled.div<{ isOpen: boolean }>`
  flex-grow: 1;
  padding: 5px;
  transition: margin-left 0.3s;
  margin-left: ${(props) => (props.isOpen ? '0px' : '0px')};  /*Ajusta a margem dependendo do estado do menu */
  width: calc(100vh - 60px);
`;

const ToggleButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
`;

const LayoutWithDrawer: React.FC = () => {
  const { isOpenMenu } = DrawerConfig();

  return (
    <LayoutContainer>
      {/* Menu lateral */}
      <DrawerContainer isOpen={isOpenMenu}>
        <Drawer isOpen={isOpenMenu} />
      </DrawerContainer>

      {/* Conteúdo principal */}
      <ContentContainer isOpen={isOpenMenu}>
        {/*<ToggleButton onClick={toggleDrawer}>
                    {isDrawerOpen ? 'Recolher' : 'Expandir'}
                </ToggleButton>*/}

        {/* Componente de rotas */}
        <RoutesComp />
      </ContentContainer>
    </LayoutContainer>
  );
};

export default LayoutWithDrawer;
