

import React, { useState } from 'react';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerFlexInline, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../StylePages';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { handleSearch } from '../../../utils/searchUtils';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import { getColumnsClientes } from '../Utils/ClientsColumns';
import { getListClients, iClients, IFormClientsData } from '../Repository/ClientesRepo';
import Sidebar from '../../../components/datagridComp/SideBar';
import FormClients from '../Forms/FormClients';
import LoadingIndicator from '../../../components/LoadingIndicator';


const EDIT_FORM = 'EDIT_FORM';
const NEW_FORM = 'NEW_FORM';

const ListClientsTab: React.FC = () => {

    const { addNotification } = useNotifications();
    const [ClientEdit, setClientEdit] = React.useState<IFormClientsData>();
    const [message, setMessage] = React.useState<string | undefined>('Carregando dados...');

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();


    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<IFormClientsData[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);


    const handleEditItem = (row: IFormClientsData) => {
        setClientEdit(row);
        setSideContent(EDIT_FORM);
    };


    const columns = getColumnsClientes(handleEditItem);


    async function getListInServer() {
        try {
            setMessage('Carregando dados...');
            const result = await getListClients();
            console.log(result)
            setRows(result);

        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        } finally {
            setMessage(undefined);
        }
    }

    React.useEffect(() => {
        getListInServer();
    }, []);


    const selectAll = () => {
        const allIds = new Set(filteredRows.map((item) => item.id));
        setSelectedRows(allIds);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }

    const handleNewClient = () => {
        setClientEdit(undefined);
        setSideContent(NEW_FORM);
    };

    const handleUpdateList = () => {
        getListInServer();
        CancelFormClient();
    };

    const CancelFormClient = () => {
        setClientEdit(undefined);
        setSideContent(undefined);
    }

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> : <>
                <SearchAndSelectContainer>
                    <ContainerSearch>
                        <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                    </ContainerSearch>
                    <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer}>
                        <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                    </BtnFaIcon >
                    {/* <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleNewClient}>
                        <BoxIcon icon={faAdd} className="fa-icon" /> Criar Cliente
                    </BtnFaIcon > */}
                </SearchAndSelectContainer>
                <ContainerInfo>
                    <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                    <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                    <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                    <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                </ContainerInfo>
                {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                    : (<>
                        <ContainerTable>
                            <DataGridComp
                                rows={filteredRows}
                                columns={columns}
                                selectedRows={selectedRows}
                                onRowClick={() => { }}
                                onRowSelect={handleRowSelect}
                            />
                        </ContainerTable>
                    </>)}

                <Sidebar width='600px' isOpen={sideContent === NEW_FORM} onClose={CancelFormClient} title={'Cadastro de Cliente'}>
                    <FormClients
                        isExcelForm={false}
                        onUpdate={handleUpdateList}
                        onCancel={CancelFormClient} />
                </Sidebar>
                {ClientEdit && <Sidebar width='700px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormClient} title={'Editar cliente'}>
                    <FormClients
                        onCancel={CancelFormClient}
                        onEditReturn={handleUpdateList}
                        initialData={ClientEdit}
                        isExcelForm={false}
                    />
                </Sidebar>}
            </>}</>

    )

}
export default ListClientsTab