import React, { createContext, useState } from 'react'
import { io, Socket } from 'socket.io-client';

import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';
import { changeAlert } from '../redux/alertServiceSlice';
import { useNotifications } from '../Notifications/NotificationContext';
import { AuthConfig } from '../Config/AuthContext';
const CONNECTION_PORT = process.env.REACT_APP_BASEURL_SOCKET ?? 'https://sistema.maxtracer.com.br';




interface AppContextInterface {
    socket: Socket;
}
let socket: Socket;
let ws: AppContextInterface;

const WebSocketContext = createContext<AppContextInterface | null>(null)


export { WebSocketContext }

interface props {
    children?: React.ReactNode;
}

const currentDomain = window.location.hostname; // Obtém o domínio atual

const WebSocket = ({ children }: props) => {

    const { user } = AuthConfig()
    const [isConnect, setIsConnect] = useState<boolean>(false)
    const [isRegister, setIsRegister] = useState<number>(0)
    const { addNotification } = useNotifications();



    if (!socket) {
        socket = io(`https://${currentDomain}`, { path: "/api/events" })

        ws = {
            socket
        };

        socket.on('connect', () => {
            console.log("..............connect socket", socket.id)
            setIsConnect(true)
        })

        socket.on('disconnect', () => {
            console.log(".............disconnect socket")
            setIsConnect(false)
        });

        socket.on('alert-event', (data: any) => {
            console.log("<< alert-event >>", data)
            let icon = data.icon ? `https://${currentDomain}/assets/${data.icon}` : undefined
            addNotification(data.title, data.msg, 'warning', icon, data.sound);
            //dispatch(changeAlert({ open: true, type: 'success', msg: data.msg, title: data.title, icon: data.icon, sound: data.sound }))
        });

        socket.on('join', () => {
            console.log("<< join >>", user?.iduser)
            setIsRegister(Math.random())
        });

    }
    React.useEffect(() => {

        if (user) {
            if (!socket.connected) {
                socket.connect()
            }
        } else {
            socket.emit('UserLogout')
            socket.disconnect()
        }
    }, [user])

    React.useEffect(() => {
        if (isConnect && user) {
            console.log("Socket Connected", socket.connected)
            socket.emit('join', user.iduser)
        }
    }, [isConnect, isRegister])


    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocket

