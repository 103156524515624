import { Column, SelectColumn } from 'react-data-grid';
import { BtnCheckBoxOptions } from '../../StylePages';
import SmallButton from '../../../components/datagridComp/SmallButton';
import { FaUser } from 'react-icons/fa';
import { iCarAlert } from '../repository/AlertsRepo';





export const getVehicleAlertsColumns = (
): Column<iCarAlert>[] => {
    return [
        // Selection Column
        {
            ...SelectColumn,
            name: 'Select',
        },
        // ID Column
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Vehicle Plate Column
        {
            key: 'placa',
            name: 'Veículo',
            width: 280,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{props.row.placa}</i> / <strong>{props.row.apelido}</strong></div>
                    </>

                )
            }
        },
        // Client Name Column
        {
            key: 'manufacturer',
            name: 'Cliente',
            width: 380,
            resizable: true,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{props.row.manufacturer}</i> / <strong>{props.row.model}</strong></div>
                    </>

                )
            }
        },

    ];
};
