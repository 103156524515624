import { Column, SelectColumn } from 'react-data-grid';
import { iUserVehicle } from '../Repository/UserRepo';


export const getColumnsUserVehicles = (): Column<iUserVehicle>[] => {
    return [
        // Coluna para seleção
        {
            ...SelectColumn,
            name: 'Selecionar'
        },
        // Coluna para ID
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Coluna para nome
        {
            key: 'name',
            name: 'Nome do Usuário',
            width: 200,
            resizable: true,
        },
        // Coluna para o nome do cliente
        {
            key: 'client_name',
            name: 'Nome do Cliente',
            width: 250,
            resizable: true,
        },

        // Coluna para login
        {
            key: 'login',
            name: 'Login',
            width: 200,
            resizable: true,
        },

    ];
};
