import React from 'react';
import { SimulatorProvider } from './utils/simulatorContext';
import SimulatorView from './views/SimulatorView';

const SimulatorPage: React.FC = () => {

    return (
        <SimulatorProvider>
            <SimulatorView />
        </SimulatorProvider>
    )

}
export default SimulatorPage