

import React from 'react';
import ListUsers from './Views/ListUsers';


const UsersPage: React.FC = () => {

    return (
        <>
            <ListUsers />
        </>
    )

}
export default UsersPage