import { useRef, useState } from "react";
import styled from "styled-components";
import { useGoogleMap } from "../../../GoogleMaps/GoogleMapProvider";
import { GeocoderService } from "../../../GoogleMaps/GeocoderService";



const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
`;

const SearchButton = styled.button<{ disabled: boolean }>`
  padding: 10px 20px;
  font-size: 16px;
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#007bff')};
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#0056b3')};
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffdddd;
  color: #a94442;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
  border: 1px solid #ebccd1;
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #a94442;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid white;
  width: 12px;
  height: 12px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;





interface SearchInMapProps {
    SearchDisable?: boolean;
    updateMarkerPoint?: (position: google.maps.LatLng) => void;
}



const SearchInMap: React.FC<SearchInMapProps> = ({ SearchDisable, updateMarkerPoint }) => {
    const [address, setAddress] = useState<string>('');
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const getAddress = async (param: string | google.maps.LatLng) => {
        setIsLoading(true);
        try {
            const result = await GeocoderService(param);
            if (result) {
                let position = typeof param === 'string' ? result.position : param;
                updateMarkerPoint?.(position);
                setAddress(result.address);
            } else {
                setErrorMessage('Endereço não encontrado.');
            }
        } catch (error) {
            console.error('Erro ao buscar endereço:', error);
            setErrorMessage('Erro ao buscar endereço.');
        } finally {
            setIsLoading(false);
        }
    }

    const handleSearch = async () => {
        if (!address || address.length < 5) return;
        getAddress(address);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setAddress(value);
        setIsButtonEnabled(value.length >= 5);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && isButtonEnabled) {
            handleSearch();
        }
    };


    return (
        <>
            <SearchContainer>
                <SearchInput
                    type="text"
                    placeholder="Digite um endereço..."
                    value={address}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    disabled={SearchDisable}
                />
                {!SearchDisable && <SearchButton onClick={handleSearch} disabled={!isButtonEnabled}>
                    {isLoading ? <Spinner /> : 'Pesquisar'}
                </SearchButton>}
            </SearchContainer>
            {errorMessage && (
                <ErrorMessage>
                    {errorMessage}
                    <CloseButton onClick={() => setErrorMessage('')}>×</CloseButton>
                </ErrorMessage>
            )}
        </>
    );
};


export default SearchInMap;