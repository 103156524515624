import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

interface ActionOption {
    name: string;
    description: string;
    type: "boolean" | "value" | "select";
    value?: boolean | string;
    options?: string[]; // Para o tipo "select"
}

interface PolicyStatement {
    Description: string;
    Resource: string;
    Actions: ActionOption[];
}

interface Policy {
    Name: string;
    Version: string;
    Statement: PolicyStatement[];
}

// Styled Components
const Container = styled.div`
  margin: 20px;
`;

const Section = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Select = styled.select`
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const AddButton = styled.button`
  padding: 8px 16px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
  color: #ff4d4f;
`;

const PolicyEditor: React.FC = () => {
    const [policy, setPolicy] = useState<Policy>({
        Name: "New Policy",
        Version: "2024-11-16",
        Statement: [],
    });

    const handlePolicyNameChange = (name: string) => {
        setPolicy({ ...policy, Name: name });
    };

    const handleAddStatement = () => {
        const newStatement: PolicyStatement = {
            Description: "",
            Resource: "",
            Actions: [],
        };
        setPolicy({ ...policy, Statement: [...policy.Statement, newStatement] });
    };

    const handleStatementChange = (
        index: number,
        key: keyof PolicyStatement,
        value: string
    ) => {
        const updatedStatements = [...policy.Statement];
        updatedStatements[index] = { ...updatedStatements[index], [key]: value };
        setPolicy({ ...policy, Statement: updatedStatements });
    };

    const handleAddAction = (statementIndex: number) => {
        const newAction: ActionOption = {
            name: "",
            description: "",
            type: "boolean",
            value: false,
        };
        const updatedStatements = [...policy.Statement];
        updatedStatements[statementIndex].Actions.push(newAction);
        setPolicy({ ...policy, Statement: updatedStatements });
    };

    const handleActionTypeChange = (
        statementIndex: number,
        actionIndex: number,
        newType: "boolean" | "value" | "select"
    ) => {
        const updatedStatements = [...policy.Statement];
        const updatedActions = [...updatedStatements[statementIndex].Actions];
        const currentAction = updatedActions[actionIndex];

        // Alterar o valor padrão com base no tipo
        let newValue: boolean | string = "";
        if (newType === "boolean") {
            newValue = false;
        } else if (newType === "value") {
            newValue = "";
        } else if (newType === "select") {
            newValue = currentAction.options?.[0] || ""; // Primeiro item das opções
        }

        updatedActions[actionIndex] = {
            ...currentAction,
            type: newType,
            value: newValue,
        };

        updatedStatements[statementIndex].Actions = updatedActions;
        setPolicy({ ...policy, Statement: updatedStatements });
    };

    const handleActionChange = (
        statementIndex: number,
        actionIndex: number,
        key: keyof ActionOption,
        value: any
    ) => {
        const updatedStatements = [...policy.Statement];
        const updatedActions = [...updatedStatements[statementIndex].Actions];
        updatedActions[actionIndex] = { ...updatedActions[actionIndex], [key]: value };
        updatedStatements[statementIndex].Actions = updatedActions;
        setPolicy({ ...policy, Statement: updatedStatements });
    };

    const handleRemoveAction = (statementIndex: number, actionIndex: number) => {
        const updatedStatements = [...policy.Statement];
        updatedStatements[statementIndex].Actions.splice(actionIndex, 1);
        setPolicy({ ...policy, Statement: updatedStatements });
    };

    return (
        <Container>
            <h1>Create/Edit Policy</h1>
            <Label>Policy Name:</Label>
            <Input
                type="text"
                value={policy.Name}
                onChange={(e) => handlePolicyNameChange(e.target.value)}
                placeholder="Enter policy name"
            />
            {policy.Statement.map((statement, statementIndex) => (
                <Section key={statementIndex}>
                    <Label>Description:</Label>
                    <Input
                        type="text"
                        value={statement.Description}
                        onChange={(e) =>
                            handleStatementChange(statementIndex, "Description", e.target.value)
                        }
                        placeholder="Enter statement description"
                    />
                    <Label>Resource:</Label>
                    <Input
                        type="text"
                        value={statement.Resource}
                        onChange={(e) =>
                            handleStatementChange(statementIndex, "Resource", e.target.value)
                        }
                        placeholder="Enter resource"
                    />
                    <h4>Actions:</h4>
                    {statement.Actions.map((action, actionIndex) => (
                        <div key={actionIndex}>
                            <IconButton
                                onClick={() => handleRemoveAction(statementIndex, actionIndex)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </IconButton>
                            <Label>Action Name:</Label>
                            <Input
                                type="text"
                                value={action.name}
                                onChange={(e) =>
                                    handleActionChange(statementIndex, actionIndex, "name", e.target.value)
                                }
                                placeholder="Enter action name"
                            />
                            <Label>Description:</Label>
                            <Input
                                type="text"
                                value={action.description}
                                onChange={(e) =>
                                    handleActionChange(
                                        statementIndex,
                                        actionIndex,
                                        "description",
                                        e.target.value
                                    )
                                }
                                placeholder="Enter action description"
                            />
                            <Label>Type:</Label>
                            <Select
                                value={action.type}
                                onChange={(e) =>
                                    handleActionTypeChange(
                                        statementIndex,
                                        actionIndex,
                                        e.target.value as "boolean" | "value" | "select"
                                    )
                                }
                            >
                                <option value="boolean">Boolean</option>
                                <option value="value">Value</option>
                                <option value="select">Select</option>
                            </Select>
                            <Label>Default Value:</Label>
                            {action.type === "boolean" && (
                                <Select
                                    value={String(action.value)}
                                    onChange={(e) =>
                                        handleActionChange(
                                            statementIndex,
                                            actionIndex,
                                            "value",
                                            e.target.value === "true"
                                        )
                                    }
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </Select>
                            )}
                            {action.type === "value" && (
                                <Input
                                    type="text"
                                    value={action.value as string}
                                    onChange={(e) =>
                                        handleActionChange(statementIndex, actionIndex, "value", e.target.value)
                                    }
                                    placeholder="Enter default value"
                                />
                            )}
                            {action.type === "select" && (
                                <>
                                    <Label>Options (comma-separated):</Label>
                                    <Input
                                        type="text"
                                        value={action.options?.join(",") || ""}
                                        onChange={(e) =>
                                            handleActionChange(
                                                statementIndex,
                                                actionIndex,
                                                "options",
                                                e.target.value.split(",")
                                            )
                                        }
                                        placeholder="Enter options separated by commas"
                                    />
                                    <Label>Select Default Option:</Label>
                                    <Select
                                        value={action.value as string}
                                        onChange={(e) =>
                                            handleActionChange(statementIndex, actionIndex, "value", e.target.value)
                                        }
                                    >
                                        {action.options?.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Select>
                                </>
                            )}
                        </div>
                    ))}
                    <AddButton onClick={() => handleAddAction(statementIndex)}>
                        <FontAwesomeIcon icon={faPlus} /> Add Action
                    </AddButton>
                </Section>
            ))}
            <AddButton onClick={handleAddStatement}>
                <FontAwesomeIcon icon={faPlus} /> Add Statement
            </AddButton>
            <pre>{JSON.stringify(policy, null, 2)}</pre>
        </Container>
    );
};

export default PolicyEditor;
