import { createContext, ReactNode, useContext, useState } from "react";
import { getCategories, getDriversByClient, getFuelTypes, getFence, getSuppliers, getTanks, getVehiclesByClient, iDriversStorage, iFuelTypeStorage, IFence, iVehicleStorage, IClient, getClients } from "./StorageRepository";

type ConfigStorage = {
    updateConfig: (newConfig: Partial<ConfigStorage>) => void;
    syncVehicles: () => Promise<iVehicleStorage[]>;
    syncDrivers: () => Promise<iDriversStorage[]>;
    syncFuel: () => Promise<iFuelTypeStorage[]>;
    syncSuppliers: () => Promise<any[]>;
    syncTanks: () => Promise<any[]>;
    syncCategories: () => Promise<any[]>;
    syncFences: () => Promise<IFence[]>;
    syncClients: () => Promise<IClient[]>;
    vehicles: any[];
    drivers: any[];
    fuelTypes: any[];
    suppliers: any[];
    tanks: any[];
    categories: any[];
    fences: IFence[];
    clients: IClient[];
};

const defaultConfig: ConfigStorage = {
    vehicles: [],
    drivers: [],
    fuelTypes: [],
    suppliers: [],
    tanks: [],
    categories: [],
    fences: [],
    clients: [],
    updateConfig: () => { },
    syncVehicles: async () => [],
    syncDrivers: async () => [],
    syncFuel: async () => [],
    syncSuppliers: async () => [],
    syncTanks: async () => [],
    syncCategories: async () => [],
    syncFences: async () => [],
    syncClients: async () => []
};

const StorageContext = createContext<ConfigStorage>(defaultConfig);

export const StorageConfig = () => {
    return useContext(StorageContext);
};

type StorageProviderProps = {
    children: ReactNode;
};

export const StorageProvider = ({ children }: StorageProviderProps) => {
    const [config, setConfig] = useState(defaultConfig);

    const updateConfig = (newConfig: Partial<ConfigStorage>) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...newConfig,
        }));
    };

    const syncVehicles = async (): Promise<iVehicleStorage[]> => {
        const vehicles = await getVehiclesByClient();
        updateConfig({ vehicles });
        return vehicles;
    }

    const syncDrivers = async (): Promise<iDriversStorage[]> => {
        const drivers = await getDriversByClient();
        updateConfig({ drivers });
        return drivers;
    }

    const syncFuel = async (): Promise<iFuelTypeStorage[]> => {
        const fuelTypes = await getFuelTypes();
        updateConfig({ fuelTypes });
        return fuelTypes;
    }

    const syncSuppliers = async (): Promise<any[]> => {
        const suppliers = await getSuppliers();
        updateConfig({ suppliers });
        return suppliers;
    }

    const syncTanks = async (): Promise<any[]> => {
        const tanks = await getTanks();
        updateConfig({ tanks });
        return tanks;
    }

    const syncCategories = async (): Promise<any[]> => {
        const categories = await getCategories();
        updateConfig({ categories });
        return categories;
    }

    const syncFences = async (): Promise<IFence[]> => {
        const fences = await getFence();
        updateConfig({ fences });
        return fences;
    }

    const syncClients = async (): Promise<IClient[]> => {
        const clients = await getClients();
        updateConfig({ clients });
        return clients;
    }


    return (
        <StorageContext.Provider value={{
            ...config,
            updateConfig,
            syncVehicles,
            syncDrivers,
            syncFuel,
            syncTanks,
            syncSuppliers,
            syncCategories,
            syncFences,
            syncClients
        }}>
            {children}
        </StorageContext.Provider>
    );
};