import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { ISimulatorData, SimulatorConfig } from '../utils/simulatorContext';
import SearchInMap from '../Components/SearchInMap';
import SimulatorCommands from '../Components/SimulatorCommands';
import { pushPostion } from '../repository/SimulatorRepo';
import { formatDateDayJs } from '../../../utils/Converter';


const LayoutContainer = styled.div`
  display: flex;
  height: 88vh;
`;

const LeftContainer = styled.div<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '680px' : '328px')};
  padding-top: 5px;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 88vh;
  align-items: center;
  overflow: auto;
`;

const ContainerMaps = styled.div`
  flex-grow: 1;
  padding: 5px;
  transition: margin-left 0.3s; /*Ajusta a margem dependendo do estado do menu */
`;


const MapContainer = styled.div`
  height: 100%; /* Garante que o mapa ocupe toda a altura do contêiner pai */
  width: 100%; /* Garante que o mapa ocupe toda a largura do contêiner pai */
  position: relative; /* Necessário para posicionar os marcadores */
`;



let markerPosition: google.maps.Marker | null = null;
let clickListener: google.maps.MapsEventListener | undefined;


let intervalMap: any
let isInitMaps = true


let valuesPaired: ISimulatorData;

const SimulatorView: React.FC = () => {
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const { addNotification } = useNotifications();
    const { updateConfig, date, values, server } = SimulatorConfig();

    const getDados = () => {

    }

    const CtrlInterval = () => {
        intervalMap = setInterval(() => {
            getDados()
        }, 5000)

    }


    React.useEffect(() => {
        if (isInitMaps) {
            isInitMaps = false;
            CtrlInterval()
            getDados()


        }
        //componentUnAmount()
        return () => {
            console.log("Destruct SimulatorPage")
            clearInterval(intervalMap)
            isInitMaps = true;
            clearMap('Destruct')
        }
    }, [])


    const clearMap = (reason: String) => {
        console.log('ClearMap', reason);
        if (clickListener) {
            console.log('Removendo listener de clique no mapa');
            google.maps.event.removeListener(clickListener);
        }
        if (markerPosition) {
            markerPosition.setMap(null);
            markerPosition = null;
        }
    }


    // Atualiza a posição do marker no mapa e ajusta o zoom se necessário
    const updateMarkerPosition = (position: google.maps.LatLng) => {
        if (markerPosition) {
            markerPosition.setMap(null); // Remove o marker anterior do mapa
        }
        map?.panTo(position); // Centraliza o mapa na posição obtida
        if (map?.getZoom()! < 15) map?.setZoom(15); // Ajusta o zoom para 15, se necessário

        markerPosition = new google.maps.Marker({
            position: position,
            map,
            draggable: true,
        });
        let data = {
            ...valuesPaired,
            lat: position.lat(),
            lng: position.lng(),
        }
        updateConfig({ values: data })
        markerPosition.addListener('dragend', () => {
            if (markerPosition?.getPosition()) {
                updateMarkerPosition(markerPosition.getPosition()!);
            }
        });
    };

    const activeClickMapToMarker = () => {
        // Remove o listener de desenho e restaura o clique padrão para atualizar o marker
        if (clickListener) {
            google.maps.event.removeListener(clickListener);
        }

        clickListener = map?.addListener('click', async (event: google.maps.MapMouseEvent) => {
            const clickPosition = event.latLng;
            if (clickPosition) {
                updateMarkerPosition(clickPosition);
            }
        });
    }


    useEffect(() => {
        console.log('Map IN Maps Open')
        if (map && mapContainerRef.current) {
            // Associa o mapa ao contêiner específico desta tela
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });

            // Move o mapa para o contêiner correto
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');

            activeClickMapToMarker()
            updateMarkerPosition(new google.maps.LatLng(-12.255304237685886, -38.95475469715996))

        }
    }, [map]);


    const handleSendData = async () => {
        try {
            const output = { ...values };
            output.gpsTime = formatDateDayJs(date);
            await pushPostion(output, server);
            addNotification('Dados Enviados', 'Dados enviados com sucesso', 'success');
        } catch (e) {
            addNotification('Erro ao enviar dados', 'Erro ao enviar dados', 'error');
        }
    };

    useEffect(() => {
        console.log(values)
        valuesPaired = values;
    }, [values]);

    return (
        <>
            <LayoutContainer>
                <LeftContainer isExpanded={true}>
                    <SimulatorCommands
                        onOutput={handleSendData}
                    />
                </LeftContainer>
                <ContainerMaps>
                    <SearchInMap
                        SearchDisable={false}
                        updateMarkerPoint={updateMarkerPosition}
                    />
                    <MapContainer ref={mapContainerRef}></MapContainer>
                </ContainerMaps>
            </LayoutContainer>
        </>
    );
};

export default SimulatorView;
