import React from 'react';
import styled from 'styled-components';

interface SearchFieldProps {
    onSearch: (searchTerm: string) => void;
    searchTerm: string;
    height?: string;
}

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0px;
`;

const StyledInput = styled.input<{ height?: string }>`
    width: 100%;
    padding: 8px 40px 8px 12px;  /* Espaço extra para o botão de apagar */
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
    height: ${({ height }) => height || "40px"}; /* Altura padrão: 40px */

    &:focus {
        border-color: #ff7a00;
        box-shadow: 0 0 0 3px rgba(255, 122, 0, 0.3);
    }
`;

const ClearButton = styled.button`
    position: absolute;
    right: 10px;
    background: transparent;
    border: none;
    color: #ff7a00;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        color: #ff5500;
    }
`;

const SearchFieldV2: React.FC<SearchFieldProps> = ({ onSearch, searchTerm, height }) => {
    //const [searchTerm, setSearchTerm] = React.useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setSearchTerm(event.target.value);
        onSearch(event.target.value);
    };

    const clearSearch = () => {
        //setSearchTerm('');
        onSearch('');
    };

    return (
        <Container>
            <StyledInput
                type="text"
                placeholder="Pesquisar"
                value={searchTerm}
                onChange={handleSearchChange}
                height={height}
            />
            {searchTerm.length > 0 && (
                <ClearButton onClick={clearSearch}>
                    &#x2716; {/* Unicode for an 'X' symbol */}
                </ClearButton>
            )}
        </Container>
    );
};

export default SearchFieldV2;
