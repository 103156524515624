



import React, { useEffect } from 'react';
import { ContainerSearch, BoxIcon, BtnFaIcon, ContainerInfo, ContainerTable, InfoBox, InfoBoxLabel, SearchAndSelectContainer, Container } from '../../StylePages';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { faClipboardCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { handleSearch } from '../../../utils/searchUtils';
import { getColumnsUserVehicles } from '../Utils/UserColumnsVehicle';
import { getListUserInVehicle, insertUsersInVehicle, iUserInVehicle, iUserVehicle } from '../Repository/UserRepo';
import NoData from '../../../components/NoData';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import SelectFieldForm from '../../../components/datagridComp/SelectFieldForm';

interface Props {
    idVehicle: number;
    onUpdated?: () => void;
}

interface IOption {
    value: string;
    label: string;
}


const optionsUserFilter: IOption[] = [
    { value: '0', label: 'Todos os Usuários' },
    { value: '2', label: 'Usuários do Sistema' },
    { value: '3', label: 'Usuários de Clientes' },
];

const ListUserInVehicle: React.FC<Props> = ({ idVehicle, onUpdated }) => {

    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [rows, setRows] = React.useState<iUserVehicle[]>([]);
    const [message, setMessage] = React.useState<string | undefined>('Carregando dados...');
    const { addNotification } = useNotifications();
    const [filterUser, setFilterUser] = React.useState<number>(0);
    const formRef = React.useRef<FormHandles>(null)
    const [showOnlySelected, setShowOnlySelected] = React.useState<boolean>(false);


    const columns = getColumnsUserVehicles();

    const filteredRows = React.useMemo(() => {
        let filtered = handleSearch(rows, findInputTerms);
        if (showOnlySelected) {
            filtered = filtered.filter((row) => selectedRows.has(row.id));
        }
        return filtered;
    }, [findInputTerms, rows, selectedRows, showOnlySelected]);

    const getListInServer = async () => {
        try {
            setMessage('Carregando dados...');
            const result = await getListUserInVehicle(idVehicle, filterUser);
            setRows(result);
            const selected: Set<number> = new Set(
                result.filter((row: { selected: boolean }) => row.selected).map((row: { id: number }) => row.id)
            ); setSelectedRows(selected);
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    };

    const handleSave = async () => {
        try {
            setMessage('Salvando dados...');
            const idusers = Array.from(selectedRows);
            let _users: iUserInVehicle[] = [];
            for (const id of idusers) {
                const user = rows.find((row) => row.id === id);
                if (user) {
                    _users.push({
                        iduser: user.id,
                        rule: Number(user.rule),
                        idgroup: user.idgroup
                    });
                }
            }
            await insertUsersInVehicle(idVehicle, _users);
            addNotification('', 'Usuários associados ao veículo com sucesso', 'success');
            onUpdated?.();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    }


    useEffect(() => {
        getListInServer();
    }, [filterUser]);

    const handleChangedSelected = async (rule: number) => {
        setFilterUser(rule);
        getListInServer();
    }

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> :
                <Container>
                    <SearchAndSelectContainer>
                        <ContainerSearch>
                            <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                        </ContainerSearch>
                        <ContainerSearch>
                            <Form placeholder={""} ref={formRef} onSubmit={console.log} noValidate={true} initialData={undefined}>
                                <SelectFieldForm
                                    options={optionsUserFilter}
                                    value={filterUser}
                                    name={'filterUser'}
                                    onChange={(e) => handleChangedSelected(Number(e.target.value) ?? 0)}
                                /></Form>
                        </ContainerSearch>
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleSave} >
                            <BoxIcon icon={faClipboardCheck} className="fa-icon" /> Salvar
                        </BtnFaIcon >
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                        <InfoBoxLabel>
                            <input
                                type="checkbox"
                                checked={showOnlySelected}
                                onChange={(e) => setShowOnlySelected(e.target.checked)}

                            />
                            Exibir somente Selecionados
                        </InfoBoxLabel>

                    </ContainerInfo>
                    {filteredRows.length === 0 ? <NoData message="Nenhum Registro Encontrado" />
                        : (<>
                            <ContainerTable height='82vh'>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columns}
                                    selectedRows={selectedRows}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable>
                        </>)}

                </Container>
            }
        </>
    )

}
export default ListUserInVehicle