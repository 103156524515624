



import React, { useEffect } from 'react';
import { ContainerSearch, BoxIcon, BtnFaIcon, ContainerInfo, ContainerTable, InfoBox, InfoBoxLabel, SearchAndSelectContainer, Container } from '../../StylePages';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import { faClipboardCheck, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { handleSearch } from '../../../utils/searchUtils';
import NoData from '../../../components/NoData';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { extractErrorMessage } from '../../../utils/CustomErros';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { getListVehiclesInAlerts, iCarAlert, insertVehicleInAlerts } from '../repository/AlertsRepo';
import { getVehicleAlertsColumns } from '../Utils/VehicleAlertsColumns';

interface Props {
    idAlert: number;
    onUpdated?: () => void;
}

let oldSelectedRows: ReadonlySet<number> = new Set();

const ListVehiclesInAlerts: React.FC<Props> = ({ idAlert, onUpdated }) => {

    const [findInputTerms, setFindInputTerms] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());
    const [rows, setRows] = React.useState<iCarAlert[]>([]);
    const [message, setMessage] = React.useState<string | undefined>('Carregando dados...');
    const { addNotification } = useNotifications();
    const [showOnlySelected, setShowOnlySelected] = React.useState<boolean>(false);


    const columns = getVehicleAlertsColumns();

    const filteredRows = React.useMemo(() => {
        let filtered = handleSearch(rows, findInputTerms);
        if (showOnlySelected) {
            filtered = filtered.filter((row) => selectedRows.has(row.id));
        }
        return filtered;
    }, [findInputTerms, rows, selectedRows, showOnlySelected]);


    const getListInServer = async () => {
        try {
            setMessage('Carregando dados...');
            const result = await getListVehiclesInAlerts(idAlert);
            setRows(result);
            const selected: Set<number> = new Set(
                result.filter((row: { selected: boolean }) => row.selected).map((row: { id: number }) => row.id)
            );
            setSelectedRows(selected);
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    };

    const handleSave = async () => {
        try {
            setMessage('Salvando dados...');
            const idvehicles = Array.from(selectedRows);
            await insertVehicleInAlerts(idvehicles, idAlert);
            addNotification('', 'Veículos associados ao Alerta com sucesso', 'success');
            onUpdated?.();
        } catch (e) {
            console.log(e);
            let message = extractErrorMessage(e)
            addNotification('', message, 'error');
        } finally {
            setMessage(undefined);
        }
    }


    useEffect(() => {
        getListInServer();
    }, []);

    return (
        <>
            {message !== undefined ? <LoadingIndicator message={message} /> :
                <Container>
                    <SearchAndSelectContainer>
                        <ContainerSearch>
                            <SearchFieldV2
                                onSearch={setFindInputTerms}
                                searchTerm={findInputTerms}
                                height={"32px"} />
                        </ContainerSearch>
                        <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={getListInServer} >
                            <BoxIcon icon={faRefresh} className="fa-icon" /> Atualizar
                        </BtnFaIcon >
                        <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleSave} >
                            <BoxIcon icon={faClipboardCheck} className="fa-icon" /> Salvar
                        </BtnFaIcon >
                    </SearchAndSelectContainer>
                    <ContainerInfo>
                        <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                        <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
                        <InfoBoxLabel>
                            <input
                                type="checkbox"
                                checked={showOnlySelected}
                                onChange={(e) => setShowOnlySelected(e.target.checked)}

                            />
                            Exibir somente Selecionados
                        </InfoBoxLabel>

                    </ContainerInfo>
                    {filteredRows.length === 0 ? <NoData message="Nenhum Registro Encontrado" />
                        : (<>
                            <ContainerTable height='81vh'>
                                <DataGridComp
                                    rows={filteredRows}
                                    columns={columns}
                                    selectedRows={selectedRows}
                                    onRowSelect={setSelectedRows}
                                />
                            </ContainerTable>
                        </>)}

                </Container>
            }
        </>
    )

}
export default ListVehiclesInAlerts