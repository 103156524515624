import styled from "styled-components";
import React from "react";

interface ButtonProps {
    color?: string; // Cor do botão
    label?: string; // Texto exibido no botão
    height?: "small" | "medium" | "large"; // Tamanho do botão
}

const heightMap = {
    small: "32px",
    medium: "40px",
    large: "48px",
};

const colorDefault = "#016912";
const colorDefaultHover = "#275700";

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color || colorDefault};
  color: white;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  padding: 10px;
  height: 22px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ color }) => color || colorDefaultHover};
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;



interface ButtonComponentProps {
    color?: string;
    label: string;
    height?: "small" | "medium" | "large";
    onClick?: () => void;
    disabled?: boolean;
}

const ButtonSmall: React.FC<ButtonComponentProps> = ({ color, label, height = "small", onClick, disabled }) => {
    return (
        <StyledButton color={color} height={height} onClick={onClick} disabled={disabled}>
            {label}
        </StyledButton>
    );
};

export default ButtonSmall;
