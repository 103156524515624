import React, { useState } from "react";
import styled from "styled-components";

interface ActionOption {
    name: string;
    description: string; // Descrição amigável
    type: "boolean" | "value" | "select";
    value?: boolean | string;
    options?: string[]; // Para o tipo "select"
}

interface PolicyStatement {
    Description: string;
    Resource: string;
    Actions: ActionOption[];
}

interface Policy {
    Name: string;
    Statement: PolicyStatement[];
}

// Styled Components
const Container = styled.div`
  margin: 20px;
`;

const Section = styled.div`
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Select = styled.select`
  padding: 8px;
  font-size: 14px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const PolicyCustomizer: React.FC = () => {
    const [policy, setPolicy] = useState<Policy>({
        Name: "Template Policy",
        Statement: [
            {
                Description: "Vehicle permissions",
                Resource: "vehicles",
                Actions: [
                    { name: "vehicle:view", description: "View vehicles", type: "boolean", value: true },
                    { name: "vehicle:edit", description: "Edit vehicles", type: "boolean", value: false },
                    { name: "vehicle:delete", description: "Delete vehicles", type: "boolean", value: false },
                    { name: "vehicle:speedlimit", description: "Set vehicle speed limit", type: "value", value: "80" },
                ],
            },
            {
                Description: "Alert permissions",
                Resource: "alerts",
                Actions: [
                    { name: "alert:view", description: "View alerts", type: "boolean", value: true },
                    {
                        name: "alert:priority",
                        description: "Set alert priority",
                        type: "select",
                        value: "high",
                        options: ["low", "medium", "high"],
                    },
                ],
            },
        ],
    });

    const handleActionChange = (
        statementIndex: number,
        actionIndex: number,
        newValue: boolean | string
    ) => {
        const updatedPolicy = { ...policy };
        updatedPolicy.Statement[statementIndex].Actions[actionIndex].value = newValue;
        setPolicy(updatedPolicy);
    };

    const handleSave = () => {
        console.log("Saved Policy:", JSON.stringify(policy, null, 2));
        alert("Policy saved successfully!");
    };

    return (
        <Container>
            <h1>Customize Policy: {policy.Name}</h1>
            {policy.Statement.map((statement, statementIndex) => (
                <Section key={statementIndex}>
                    <h3>{statement.Description}</h3>
                    <p>
                        <strong>Resource:</strong> {statement.Resource}
                    </p>
                    {statement.Actions.map((action, actionIndex) => (
                        <ActionRow key={actionIndex}>
                            <Label>{action.description}</Label>
                            {action.type === "boolean" && (
                                <div>
                                    <Checkbox
                                        type="checkbox"
                                        checked={action.value as boolean}
                                        onChange={(e) =>
                                            handleActionChange(statementIndex, actionIndex, e.target.checked)
                                        }
                                    />
                                    {action.name}
                                </div>
                            )}
                            {action.type === "value" && (
                                <Input
                                    type="text"
                                    value={action.value as string}
                                    onChange={(e) =>
                                        handleActionChange(statementIndex, actionIndex, e.target.value)
                                    }
                                    placeholder={`Set ${action.description}`}
                                />
                            )}
                            {action.type === "select" && (
                                <Select
                                    value={action.value as string}
                                    onChange={(e) =>
                                        handleActionChange(statementIndex, actionIndex, e.target.value)
                                    }
                                >
                                    {action.options?.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </ActionRow>
                    ))}
                </Section>
            ))}
            <SaveButton onClick={handleSave}>Save Policy</SaveButton>
        </Container>
    );
};

export default PolicyCustomizer;
