import { AxiosError } from "axios";

export default class CustomError extends Error {
    constructor(
        public statuscode: number,
        public message: string,
        public code: number,
        public type: string
    ) {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}

export const extractErrorMessage = (error: unknown): string => {
    console.log('extractErrorMessage', error);
    if (error instanceof AxiosError) {
        const { response, message, code } = error;
        let errorData = response?.data
        if (errorData) {
            return `${errorData.message}`;
        } else {
            return `${message}`;
        }
    } else if (error instanceof Error) {
        console.log('error instanceof Error', error);
        return `${error.message}`;
    } else {
        return 'Erro desconhecido.';
    }
};
