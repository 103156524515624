import { httpAxiosV4 } from "../gateways/Axios";






export async function getVehiclesByClient(): Promise<any> {
    const { data } = await httpAxiosV4<any, iVehicleStorage[]>(
        `vehicles`,
        "GET"
    );
    return data;
}


export async function getDriversByClient(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `drivers`,
        "GET"
    );
    return data;
}


export async function getFuelTypes(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses/fuel?sync_version=0`,
        "GET"
    );
    return data;
}

export async function getSuppliers(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `supplier`,
        "GET"
    );
    return data;
}


export async function getTanks(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `fuel/stock/tanks`,
        "GET"
    );
    return data;
}


export async function getCategories(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `expenses/categ?sync_version=0`,
        "GET"
    );
    return data;
}

export async function getFence(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `fence`,
        "GET"
    );
    return data;
}

export async function getClients(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `clients/options`,
        "GET"
    );
    return data;
}


export interface iDriversStorage {
    id: number
    DriverName: string
    DriverAvatar?: string
    DriverCard?: string
    idperfil?: number
    isJornada?: number
    veiculoId?: number
    tel?: string
}

export interface iVehicleStorage {
    id: number;
    manufacturer: string;
    model: string;
    placa: string;
    fuel_primary: number;
    km_last_abast: number;
    sync_version: number;
    apelido: string;
    icon: string;
    odometer_vehicle: number;
    client_name?: string;
    idclient?: number;
}


export interface iFuelTypeStorage {
    fuel_cod: number;
    id: number;
    descr: string;
    color: string;
    norder: number;
}

export interface IFence {
    id: number;
    descr: string;
    icon: string;
    address: string;
    lat: number;
    lng: number;
    raio: number;
    area: number;
    encod: string;
    typecerca: string;
    color: string;
}

export interface IClient {
    id: number;
    name: string;
    cnpj: string;
}