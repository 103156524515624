import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material'; // Importa o indicador de carregamento do Material-UI.

type LoadingIndicatorProps = {
    message?: string; // Mensagem opcional a ser exibida.
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ message = 'Carregando...' }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="200px" // Ajuste conforme necessário
        >
            {/* Indicador de carregamento */}
            <CircularProgress style={{ marginBottom: '16px' }} />

            {/* Mensagem opcional */}
            <Typography variant="h6" style={{ color: '#7f7f7f' }}>
                {message}
            </Typography>
        </Box>
    );
};

export default LoadingIndicator;
