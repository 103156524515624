import { Column, SelectColumn } from 'react-data-grid';
import { BtnCheckBox } from '../Style/StyleDevices';
import SmallButton from '../../../components/datagridComp/SmallButton';
import { IDevicesData } from '../Repository/DevicesRepo';

export const getColumnsDevicesList = (
    editItem: (row: IDevicesData) => void
): Column<IDevicesData>[] => {
    return [
        // Selection Column
        {
            ...SelectColumn,
            name: 'Select',
        },
        // Action Column
        {
            key: 'edit',
            name: 'Actions',
            width: 90,
            renderCell(props: any) {
                return (
                    <>
                        <BtnCheckBox onClick={() => editItem(props.row)}>
                            ✏️ Edit
                        </BtnCheckBox>
                    </>
                );
            },
        },
        // ID Column
        {
            key: 'vehicle_id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Vehicle Plate Column
        {
            key: 'vehicle_plate',
            name: 'Veículo',
            width: 280,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{props.row.vehicle_plate}</i> / <strong>{props.row.vehicle_model_name}</strong></div>
                    </>

                )
            }
        },
        // Client Name Column
        {
            key: 'client_name',
            name: 'Cliente',
            width: 200,
            resizable: true,
        },


        // Device IMEI Column
        {
            key: 'device_imei',
            name: 'Equipamento',
            width: 220,
            renderCell(props: any) {
                return (
                    props.row.device_imei ? <>
                        <div><i>{props.row.device_imei}</i> / <strong>{props.row.manufacturer_name}-{props.row.model_reference}</strong></div>
                    </> : <SmallButton label='Selecionar Equipamento' onClick={() => {
                    }
                    } />
                )
            }
        },

        // SIM Serial Column
        {
            key: 'sim_serial',
            name: 'Chip',
            width: 280,
            renderCell(props: any) {
                return (
                    props.row.sim_serial ? <>
                        <div><i>{props.row.sim_serial}</i> / <strong>{props.row.sim_operator}-{props.row.sim_provider_name}</strong></div>
                    </> : <SmallButton label='Selecionar Chip' onClick={() => {
                    }
                    } />
                )
            }
        },
        // Registration Date Column
        {
            key: 'client_registration_date',
            name: 'Registration Date',
            width: 150,
            resizable: true,
        },

        // Time Since Last Transmission Column
        {
            key: 'time_since_last_transmission_in_seconds',
            name: 'Last Transmission (s)',
            width: 200,
            resizable: true,
        },
        // Plan Value Column
        {
            key: 'plan_value',
            name: 'Plan Value',
            width: 120,
            resizable: true,
        },
        // Plan Title Column
        {
            key: 'plan_title',
            name: 'Plan',
            width: 150,
            resizable: true,
        },

        // Vehicle Registration Date Column
        {
            key: 'vehicle_registration_date',
            name: 'Registration',
            width: 150,
            resizable: true,
        },
    ];
};
