

import React from 'react';
import styled from 'styled-components';

interface CheckboxFieldProps {
    name: string;
    label: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
`;

const CheckboxLabel = styled.label`
  font-size: 14px;
  color: #333;
`;

const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, label, checked, onChange }) => {
    return (
        <CheckboxContainer>
            <CheckboxInput
                type="checkbox"
                name={name}
                checked={checked}
                onChange={onChange}
            />
            <CheckboxLabel htmlFor={name}>{label}</CheckboxLabel>
        </CheckboxContainer>
    );
};

export default CheckboxField;
