import axios from "axios";

type httpMethods = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";

export const httpAxiosV4 = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${process.env.REACT_APP_XSMART_APIV4}/${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err) {
        throw err;
    }
};

export const httpReport = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T,
    responseType: 'json' | 'blob' = 'json'
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${process.env.REACT_APP_REPORT_API}/${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: responseType,
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err) {
        throw err;
    }
};

export const httpGateway = async <T, K>(
    route: string,
    method: httpMethods,
    body?: T
) => {
    const token = window.localStorage.getItem("token");

    try {
        return await axios<K>(
            `${route}`,
            body
                ? {
                    method,
                    data: body,
                    headers: { Authorization: `Bearer ${token}` },
                }
                : { method, headers: { Authorization: `Bearer ${token}` } }
        );
    } catch (err) {
        throw err;
    }
};