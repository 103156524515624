import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEye, FaFilePdf, FaFileExcel } from 'react-icons/fa'; // Importando ícones

const ButtonGroupContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledButton = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid ${(props) => (props.selected ? '#00c853' : '#e0e0e0')};
  background-color: #fff; /* Mantém o fundo sempre branco */
  color: '#333';
  border-radius: 8px;
  transition: border-color 0.3s ease, color 0.3s ease;
  outline: none;

  &:hover {
    border-color: #00c853; /* Efeito hover apenas na borda */
  }

  svg {
    margin-right: 8px; // Espaço entre ícone e texto
  }
`;

const GenerateButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #00c853;
  color: #fff;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  outline: none;

  &:hover {
    background-color: #009624;
  }
`;

interface ButtonGroupModeProps {
    onGenerateReport: (mode: string) => void; // Função que será chamada ao clicar em "Gerar Relatório"
    isLoading?: boolean; // Indica se o relatório está sendo gerado
}

const ButtonGroupMode: React.FC<ButtonGroupModeProps> = ({ onGenerateReport, isLoading }) => {
    const [selectedButton, setSelectedButton] = useState<string | null>('pdf');

    const handleButtonClick = (button: string) => {
        setSelectedButton(button);
    };

    const getGenerateButtonText = () => {
        switch (selectedButton) {
            case 'pdf':
                return 'Gerar PDF';
            case 'excel':
                return 'Gerar Excel';
            default:
                return 'Gerar Relatório';
        }
    };

    const handleGenerateClick = () => {
        if (selectedButton) {
            onGenerateReport(selectedButton); // Chama a função do pai com o tipo de relatório
        }
    };

    return (
        <ButtonGroupContainer>
            {/* <StyledButton
                selected={selectedButton === 'view'}
                onClick={() => handleButtonClick('view')}
            >
                <FaEye size={20} />
                Em Tela
            </StyledButton> */}
            <StyledButton
                selected={selectedButton === 'pdf'}
                onClick={() => handleButtonClick('pdf')}
            >
                <FaFilePdf size={20} />
                PDF
            </StyledButton>
            {/* <StyledButton
                selected={selectedButton === 'excel'}
                onClick={() => handleButtonClick('excel')}
            >
                <FaFileExcel size={20} />
                Excel
            </StyledButton> */}
            <GenerateButton onClick={handleGenerateClick} disabled={isLoading}>
                {isLoading ? 'Gerando...' : getGenerateButtonText()}
            </GenerateButton>
        </ButtonGroupContainer>
    );
};

export default ButtonGroupMode;
