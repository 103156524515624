

import React, { useEffect, useState } from 'react';
import { BoxIcon, BtnCheckBox, BtnFaIcon, ContainerFlexInline, ContainerInfo, ContainerSearch, ContainerTable, InfoBox, SearchAndSelectContainer } from '../../StylePages';
import { read, utils } from 'xlsx';
import { getColumnsClientesExcel } from '../Utils/ClientsExcelColumns';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import { faAdd, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { handleSearch } from '../../../utils/searchUtils';
import DataGridComp from '../../../components/datagridComp/DataGridComp';
import NoData from '../../../components/NoData';
import { createClient, iClientsExcel, IFormClientsData } from '../Repository/ClientesRepo';
import { AuthConfig } from '../../../Config/AuthContext';
import Sidebar from '../../../components/datagridComp/SideBar';
import FormClients from '../Forms/FormClients';
import { extractErrorMessage } from '../../../utils/CustomErros';




var reader = new FileReader();
const EDIT_FORM = 'EDIT_FORM';

const ImportClientsTab: React.FC = () => {

    const { user } = AuthConfig()
    const { addNotification } = useNotifications();

    const [selectedFile, setSelectedFile] = React.useState<File>()
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [ClientEdit, setClientEdit] = React.useState<IFormClientsData>();


    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();

    //datagrid
    const [selectedRows, setSelectedRows] = React.useState((): ReadonlySet<number> => new Set());
    const [rows, setRows] = React.useState<IFormClientsData[]>([]);
    const [findInputTerms, setFindInputTerms] = useState<string>('');
    const filteredRows = React.useMemo(() => {
        return handleSearch(rows, findInputTerms);
    }, [findInputTerms, rows]);



    const handleEditItem = (row: IFormClientsData) => {
        setClientEdit(row);
        setSideContent(EDIT_FORM);
    };

    const columns = getColumnsClientesExcel(handleEditItem);

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
    };

    useEffect(() => {
        if (isFilePicked) {
            handleSubmission();
        }
    }, [isFilePicked]);


    const handleSubmission = async () => {
        try {

            reader.onload = function (e) {
                var f = e.target?.result;
                const wb = read(f, { type: 'binary' });
                const data = utils.sheet_to_json<iClientsExcel>(wb.Sheets[wb.SheetNames[0]]);
                let newdata = data.map((item, i) => {
                    let out: IFormClientsData = {
                        id: i,
                        status: 'Pronto',
                        cliente_idempresa: user?.idempresa || 0,
                        cliente_tipo: 1,
                        cliente_cpf: item.Cpf,
                        cliente_rg: item.Rg,
                        cliente_nome: item.nome,
                        cliente_email: item.Email,
                        cliente_tel1: item.Celular,
                        cliente_tel2: item.Telefone,
                        cliente_ender: item.Endereco,
                        cliente_bairro: item.Bairro,
                        cliente_numero: item.num,
                        cliente_cep: '',
                        cliente_cidade: item.Cidade,
                        cliente_uf: item.Estado,
                        login: item.Email,
                        password: '1234'
                    }
                    return out

                })
                setRows(newdata)
                addNotification('Leitura do Arquivo', 'Arquivo Extraido com sucesso', 'success');

            }
            reader.readAsArrayBuffer(selectedFile as unknown as Blob);
        } catch (e) {
            let error = e as Error
            console.log('[ReadExcel]', error)
            addNotification('Erro ao ler arquivo', error.message, 'error');
        }
    }

    const selectAll = () => {
        const allIds = new Set(filteredRows.map((item) => item.id));
        setSelectedRows(allIds);
    };

    const handleRowSelect = (selecteds: ReadonlySet<number>) => {
        setSelectedRows(selecteds)
    }



    const CancelFormClient = () => {
        setClientEdit(undefined);
        setSideContent(undefined);
    }


    const alterStatus = (id: number, status: string) => {
        let newRows = [...rows]
        let index = newRows.findIndex((item) => item.id === id)
        newRows[index].status = status
        setRows(newRows)
    }

    const sendToServer = async (item: IFormClientsData) => {
        alterStatus(item.id, 'Enviando...')
        try {
            await createClient(item)
            alterStatus(item.id, 'Salvo com sucesso')
        } catch (e) {
            const errorMessage = extractErrorMessage(e);
            alterStatus(item.id, '[Erro] ' + errorMessage)
        }
    }

    const handleSave = async () => {
        if (selectedRows.size === 0) {
            addNotification('Salvar Registros', 'Nenhum registro selecionado', 'error');
            return
        }
        let selected = rows.filter((item) => selectedRows.has(item.id))
        for (let i = 0; i < selected.length; i++) {
            let item = selected[i]
            await sendToServer(item)
        }

        addNotification('Salvando Registros', 'Registros Salvos com sucesso', 'success');
    }

    const handleUpdateList = (value: IFormClientsData) => {
        console.log('[UpdateList]', value)
        let newRows = [...rows]
        let index = newRows.findIndex((item) => item.id === value.id)
        newRows[index] = value
        setRows(newRows)
        CancelFormClient();
    };

    return (
        <>
            <ContainerFlexInline>
                <input type="file" name="file" onChange={changeHandler} />
            </ContainerFlexInline>
            <SearchAndSelectContainer>
                <ContainerSearch>
                    <SearchFieldV2 onSearch={setFindInputTerms} searchTerm={findInputTerms} />
                </ContainerSearch>
                <BtnFaIcon iconColor="darkorange" title='Atualizar registros' onClick={handleSubmission}>
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Recarregar Excel
                </BtnFaIcon >
                <BtnFaIcon iconColor="darkgreen" title='Atualizar registros' onClick={handleSave}>
                    <BoxIcon icon={faRefresh} className="fa-icon" /> Salvar Selecionados
                </BtnFaIcon >

            </SearchAndSelectContainer>
            <ContainerInfo>
                <BtnCheckBox onClick={selectAll}>✅ Selecionar Tudo</BtnCheckBox>
                <BtnCheckBox onClick={() => setSelectedRows(new Set())}> ❌ Desmarcar Tudo</BtnCheckBox>
                <InfoBox>Exibindo {filteredRows.length} de {rows.length}</InfoBox>
                <InfoBox>Selecionados {selectedRows.size} de {rows.length}</InfoBox>
            </ContainerInfo>
            {filteredRows.length === 0 ? <NoData message="Sem Registros" />
                : (<>
                    <ContainerTable>
                        <DataGridComp
                            rows={filteredRows}
                            columns={columns}
                            selectedRows={selectedRows}
                            onRowClick={() => { }}
                            onRowSelect={handleRowSelect}
                        />
                    </ContainerTable>
                </>)}


            {ClientEdit && <Sidebar width='700px' isOpen={sideContent === EDIT_FORM} onClose={CancelFormClient} title={'Cadastro de Cliente'}>
                <FormClients
                    onCancel={CancelFormClient}
                    onEditReturn={handleUpdateList}
                    initialData={ClientEdit}
                    isExcelForm={true}
                />
            </Sidebar>}
        </>
    )

}
export default ImportClientsTab