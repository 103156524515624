import React, { useState } from 'react';

declare const require: {
    context: (path: string, deep?: boolean, filter?: RegExp) => {
        keys: () => string[];
        (key: string): string;
    };
};


const IconGrid: React.FC = () => {
    // Função para carregar os ícones dinamicamente
    const importIcons = () => {
        const context = require.context('../../assets/icons_rotate', false, /\.(png|jpe?g|svg)$/);
        return context.keys().map(context);
    };

    // Lista de ícones carregados dinamicamente
    const icons = importIcons();

    // Estado para ícone selecionado
    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

    return (
        <div>
            <h1>Escolha um Ícone</h1>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(48px, 1fr))',
                    gap: '5px',
                    padding: '5px',
                }}
            >
                {icons.map((icon, index) => (
                    <div
                        key={index}
                        onClick={() => setSelectedIcon(icon)}
                        style={{
                            cursor: 'pointer',
                            border: selectedIcon === icon ? '2px solid blue' : '1px solid #ccc',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '48px',
                            padding: '8px',
                        }}
                    >
                        <img
                            src={icon}
                            alt={`Icon ${index}`}
                            style={{ width: '32px', height: 'auto', objectFit: 'contain' }}
                        />
                    </div>
                ))}
            </div>
            {selectedIcon && (
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <h2>Ícone Selecionado:</h2>
                    <img src={selectedIcon} alt="Ícone Selecionado" style={{ width: '32px', height: 'auto' }} />
                </div>
            )}
        </div>
    );
};

export default IconGrid;
