import { Column, SelectColumn } from 'react-data-grid';
import { BtnCheckBoxOptions } from '../../StylePages';
import SmallButton from '../../../components/datagridComp/SmallButton';
import { FaUser } from 'react-icons/fa';
import { iCarAlert } from '../repository/AlertsRepo';

export interface iFence {
    id_cerca_alert: number;
    idcerca: number;
    descr: string;
    icon: string;
    address: string;
    lat: number;
    lng: number;
    raio: number;
    encod: string;
    typeCerca: string;
    selected: boolean;
}


export const getCercasAlertsColumns = (
): Column<iCarAlert>[] => {
    return [
        // Selection Column
        {
            ...SelectColumn,
            name: 'Select',
        },
        // ID Column
        {
            key: 'id',
            name: 'ID',
            width: 80,
            resizable: true,
        },
        // Description Column
        {
            key: 'descr',
            name: 'Descrição',
            width: 200,
            resizable: true,
        },
        // Address Column
        {
            key: 'address',
            name: 'Endereço',
            width: 280,
            resizable: true,
        },
        // Latitude Column
        {
            key: 'lat',
            name: 'Latitude',
            width: 200,
            resizable: true,
            renderCell(props: any) {
                return (
                    <>
                        <div><i>{Number(props.row.lat).toFixed(6)}</i> / {Number(props.row.lng).toFixed(6)}</div>
                    </>

                )
            }
        },
        // Radius Column
        {
            key: 'raio',
            name: 'Raio',
            width: 200,
            resizable: true,
        },
    ];
};
